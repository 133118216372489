:root {
  --magic: #6851FF;
  --magicLighter: #A796FF;
  --magicLightest: #E2DCFF;
  --magicDarker: #4E3BDB;
  --magicDarkest: #3728B7;
  --money: #00C988;
  --moneyLighter: #5FEEA4;
  --moneyLightest: #C9FCD8;
  --moneyDarker: #00AC85;
  --moneyDarkest: #00907E;
  --gold: #BF9046;
  --goldLighter: #EBD092;
  --goldLightest: #FBF4DB;
  --goldDarker: #A47333;
  --goldDarkest: #895923;
}
:root {
  --white: #ffffff;
  --black: #000000;
  --gray: #757575;
  --grayLighter: #BDBDBD;
  --grayLightest: #EEEEEE;
  --grayDarker: #545454;
  --grayDarkest: #212121;
  --red: #FF5635;
  --redLighter: #FBA67F;
  --redDarker: #D02F20;
}
:root {
  --blockPaddingTop: 25px;
  --blockPaddingLeft: 25px;
  --blockPaddingBottom: 30px;
  --blockPaddingRight: 25px;
}
:root {
  --minimumModalWidth: 300px;
  --desiredModalWidth: 350px;
  --modalMarginBuffer: 10px;
  --modalActionsHeight: 60px;
  --testModeBuffer: 8px;
  --footerHeight: 60px;
}
@font-face {
  font-family: 'SourceCodePro';
  src: url(/static/media/SourceCodePro-Regular.43bb4cbf.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'SourceCodePro';
  src: url(/static/media/SourceCodePro-Medium.0cdb648a.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(/static/media/ProximaNova-Medium.eda30cbf.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'ProximaNova';
  src: url(/static/media/ProximaNova-Bold.120c6c91.otf);
  font-weight: 500;
}
*,
*:before,
*:after {
  font-family: ProximaNova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
body {
  font-weight: 400;
}
.grecaptcha-badge {
  display: none !important;
}
.error-message {
  color: #FF5635;
  line-height: 13px;
  font-size: 11px;
  padding-bottom: 5px;
  font-weight: 500;
}
.font-monospace {
  font-family: 'SourceCodePro';
}
.font-address {
  font-family: 'SourceCodePro';
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
}
.font-greeting-label {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 130%;
}
.font-button-label {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.font-description {
  font-size: 16px;
  text-align: center;
  line-height: 150%;
}
.font-description-narrow {
  font-size: 16px;
  text-align: center;
  line-height: 130%;
}
.font-item-label {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.font-sub-description {
  font-size: 14px;
}
.font-small-font {
  font-size: 12px;
}
@-webkit-keyframes "shake" {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes "shake" {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 100;
}
input {
  outline: none;
  border: none;
  margin-left: 0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
b {
  all: inherit;
  display: inline;
  font-weight: bold;
}
i {
  all: inherit;
  display: inline;
  font-style: italic;
}
button {
  padding: 0;
}
a {
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  text-decoration: underline;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}
.is-dark-theme a:hover,
.is-dark-themea:hover {
  color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
  -webkit-text-decoration-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
          text-decoration-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme a:hover,
.is-light-themea:hover {
  color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
  -webkit-text-decoration-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
          text-decoration-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}

.Modal-component .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -webkit-animation: modal-wrapper-reveal 300ms;
          animation: modal-wrapper-reveal 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: opacity;
  -webkit-print-color-adjust: exact;
}
.is-light-theme .Modal-component .modal-wrapper,
.is-light-theme.Modal-component .modal-wrapper {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.45) 0%, rgba(255, 255, 255, 0.72) 100%);
}
.is-dark-theme .Modal-component .modal-wrapper,
.is-dark-theme.Modal-component .modal-wrapper {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.72) 100%);
}
@-webkit-keyframes modal-wrapper-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modal-wrapper-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Modal-component .modal-wrapper.closing {
  -webkit-animation: modal-wrapper-conceal 300ms;
          animation: modal-wrapper-conceal 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes modal-wrapper-conceal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes modal-wrapper-conceal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.Modal-component .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.Modal-component .content-wrapper .test-mode {
  border: 2px solid #FF5635;
}
.Modal-component .modal-base {
  display: flex;
  flex-direction: column;
  width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 10px;
  overflow: hidden;
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  border-radius: 14px;
  -webkit-animation: modal-enter 300ms;
          animation: modal-enter 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: transform, opacity;
}
@media screen and (min-width: 370px) {
  .Modal-component .modal-base {
    width: 350px;
  }
}
.is-light-theme .Modal-component .modal-base,
.is-light-theme.Modal-component .modal-base {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}
.is-dark-theme .Modal-component .modal-base,
.is-dark-theme.Modal-component .modal-base {
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.1);
}
@-webkit-keyframes modal-enter {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes modal-enter {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
.Modal-component .modal-base.closing {
  -webkit-animation: modal-exit 300ms;
          animation: modal-exit 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes modal-exit {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    opacity: 0;
  }
}
@keyframes modal-exit {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    opacity: 0;
  }
}
.Modal-component .modal-loader {
  height: 100%;
  width: 100%;
  cursor: not-allowed;
  z-index: 100;
  position: absolute;
}
.Modal-component .modal-loader svg {
  position: absolute;
  left: 41%;
  top: 41%;
  width: 60px;
}
.Modal-component .page-container {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.Modal-component .page-container .modal-page {
  padding-bottom: var(--footerHeight);
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
}
.Modal-component .page-container .modal-page .PageTransition-component {
  padding-top: var(--blockPaddingTop);
  padding-left: var(--blockPaddingLeft);
  padding-right: var(--blockPaddingRight);
  padding-bottom: var(--blockPaddingBottom);
}
.Modal-component .page-container .modal-page.modal-page-test-mode .PageTransition-component {
  --blockPaddingBottom: 40px;
}
.Modal-component .page-container .footer-container {
  position: fixed;
  bottom: 0;
  height: var(--footerHeight);
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
@supports not ((-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px))) {
  .Modal-component .page-container .footer-container {
    background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  }
}
@supports ((-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px))) {
  .Modal-component .page-container .footer-container {
    background-color: rgba(var(--secondaryColor), 0.7);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
  }
}

.CountryCodeSelectBlock-component .country-list {
  margin-top: 24px;
  max-height: 330px;
}
.CountryCodeSelectBlock-component .country {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
}
.CountryCodeSelectBlock-component .country:last-child {
  margin-bottom: var(--footerHeight);
}
.CountryCodeSelectBlock-component .country > * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.CountryCodeSelectBlock-component .country.has-focus,
.CountryCodeSelectBlock-component .country:hover {
  --primaryColorAlpha: 1;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.CountryCodeSelectBlock-component .country.has-focus > *,
.CountryCodeSelectBlock-component .country:hover > * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
}
.CountryCodeSelectBlock-component .country:hover {
  --primaryColorAlpha: 0.8;
}
.CountryCodeSelectBlock-component .country:hover:active {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CountryCodeSelectBlock-component .country:hover:active,
.is-light-theme.CountryCodeSelectBlock-component .country:hover:active {
  background-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-dark-theme .CountryCodeSelectBlock-component .country:hover:active,
.is-dark-theme.CountryCodeSelectBlock-component .country:hover:active {
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.CountryCodeSelectBlock-component .country > *:first-child {
  width: 24px;
  height: 18px;
  flex-shrink: 0;
  margin-right: 15px;
}
.CountryCodeSelectBlock-component .country > *:nth-child(2) {
  flex-grow: 1;
}
.CountryCodeSelectBlock-component .country > *:last-child {
  flex-shrink: 0;
}
.CountryCodeSelectBlock-component .separator {
  width: 100%;
  height: 1px;
}
.is-light-theme .CountryCodeSelectBlock-component .separator,
.is-light-theme.CountryCodeSelectBlock-component .separator {
  background-color: #BDBDBD;
}
.is-dark-theme .CountryCodeSelectBlock-component .separator,
.is-dark-theme.CountryCodeSelectBlock-component .separator {
  background-color: #545454;
}

.PincodeInput-component {
  --border: 2px solid #757575;
  --border-selected: 2px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: text;
}
.PincodeInput-component.shake {
  -webkit-animation: 0.5s shake 1 ease-in-out;
          animation: 0.5s shake 1 ease-in-out;
}
.PincodeInput-component.disabled {
  opacity: 0.5;
}
.PincodeInput-component.validating .pincode-input-styling .styled-input {
  -webkit-animation: 0.3s validating 1 linear;
          animation: 0.3s validating 1 linear;
}
@-webkit-keyframes validating {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-70%);
            transform: translateY(-70%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes validating {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-70%);
            transform: translateY(-70%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.PincodeInput-component .pincode-input-styling {
  height: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PincodeInput-component .pincode-input-styling.has-gutter {
  margin-right: 30px;
}
.PincodeInput-component .pincode-input-styling * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.PincodeInput-component .pincode-input-styling .styled-input {
  box-sizing: border-box;
  height: 29px;
  width: 29px;
  border: var(--border);
  border-radius: 100%;
}
.PincodeInput-component .pincode-input-styling .styled-input.has-gutter {
  margin-right: 15px;
}
.PincodeInput-component .pincode-input-styling .styled-input.selected {
  border: var(--border-selected);
}
.PincodeInput-component .pincode-input-styling .styled-input.filled {
  border: var(--border-selected);
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.PincodeInput-component input {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: transparent;
  caret-color: transparent;
  box-shadow: none;
  /* For FireFox: removes red border */
}

.HiddenInput-component {
  position: relative;
}
.HiddenInput-component > input {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: transparent;
  caret-color: transparent;
  box-shadow: none;
  /* For FireFox: removes red border */
}

.PhoneInput-component {
  display: flex;
  align-items: center;
  height: 50px;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.is-dark-theme .PhoneInput-component,
.is-dark-theme.PhoneInput-component {
  --border: 2px solid #757575;
  --tertiaryColorAlpha: 0.6;
}
.is-light-theme .PhoneInput-component,
.is-light-theme.PhoneInput-component {
  --border: 2px solid #757575;
  --tertiaryColorAlpha: 0.3;
}
.PhoneInput-component.has-focus {
  --border: 2px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.PhoneInput-component.disabled {
  opacity: 0.5;
}
.PhoneInput-component .country-code-container {
  height: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  overflow: hidden;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border: var(--border);
}
.is-dark-theme .PhoneInput-component .country-code-container:active,
.is-dark-theme.PhoneInput-component .country-code-container:active {
  --border: 2px solid rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .PhoneInput-component .country-code-container:active,
.is-light-theme.PhoneInput-component .country-code-container:active {
  --border: 2px solid rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.PhoneInput-component .country-code-container .country-code {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  height: 100%;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.PhoneInput-component .country-code-container .country-code img {
  width: 22px;
  height: 15px;
}
.PhoneInput-component .country-code-container .country-code div {
  --tertiaryColorAlpha: 1;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  margin-left: 7px;
}
.is-light-theme .PhoneInput-component .country-code-container .country-code,
.is-light-theme.PhoneInput-component .country-code-container .country-code {
  background-color: #EEEEEE;
}
.is-dark-theme .PhoneInput-component .country-code-container .country-code,
.is-dark-theme.PhoneInput-component .country-code-container .country-code {
  background-color: #212121;
}
.PhoneInput-component .country-code-container .country-code:hover {
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
}
.is-light-theme .PhoneInput-component .country-code-container .country-code:active,
.is-light-theme.PhoneInput-component .country-code-container .country-code:active {
  background-color: #BDBDBD;
}
.is-dark-theme .PhoneInput-component .country-code-container .country-code:active,
.is-dark-theme.PhoneInput-component .country-code-container .country-code:active {
  background-color: #545454;
}
.PhoneInput-component .TextInput-component {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.TextInput-component {
  display: flex;
  align-items: center;
  height: 50px;
  border: var(--border);
  border-radius: 7px;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .TextInput-component,
.is-dark-theme.TextInput-component {
  --border: 2px solid #757575;
  --tertiaryColorAlpha: 0.6;
}
.is-light-theme .TextInput-component,
.is-light-theme.TextInput-component {
  --border: 2px solid #757575;
  --tertiaryColorAlpha: 0.3;
}
.TextInput-component input {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  min-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  box-shadow: none;
  /* Firefox: remove red border */
  font-size: 18px;
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --tertiaryColorAlpha: 1;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  -webkit-appearance: none;
  /* Safari: remove inset shadow */
}
.TextInput-component input.has-icon-left {
  padding-left: 10px;
}
.TextInput-component input.has-icon-right {
  padding-right: 10px;
}
.TextInput-component input::-webkit-input-placeholder {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.TextInput-component input::-moz-placeholder {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.TextInput-component input::-ms-input-placeholder {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.TextInput-component input::placeholder {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.is-light-theme .TextInput-component input::-webkit-input-placeholder, .is-light-theme.TextInput-component input::-webkit-input-placeholder {
  --tertiaryColorAlpha: 0.3;
}
.is-light-theme .TextInput-component input::-moz-placeholder, .is-light-theme.TextInput-component input::-moz-placeholder {
  --tertiaryColorAlpha: 0.3;
}
.is-light-theme .TextInput-component input::-ms-input-placeholder, .is-light-theme.TextInput-component input::-ms-input-placeholder {
  --tertiaryColorAlpha: 0.3;
}
.is-light-theme .TextInput-component input::placeholder,
.is-light-theme.TextInput-component input::placeholder {
  --tertiaryColorAlpha: 0.3;
}
.is-dark-theme .TextInput-component input::-webkit-input-placeholder, .is-dark-theme.TextInput-component input::-webkit-input-placeholder {
  --tertiaryColorAlpha: 0.6;
}
.is-dark-theme .TextInput-component input::-moz-placeholder, .is-dark-theme.TextInput-component input::-moz-placeholder {
  --tertiaryColorAlpha: 0.6;
}
.is-dark-theme .TextInput-component input::-ms-input-placeholder, .is-dark-theme.TextInput-component input::-ms-input-placeholder {
  --tertiaryColorAlpha: 0.6;
}
.is-dark-theme .TextInput-component input::placeholder,
.is-dark-theme.TextInput-component input::placeholder {
  --tertiaryColorAlpha: 0.6;
}
.TextInput-component.has-focus {
  --border: 2px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
  z-index: 4;
}
.TextInput-component.has-focus .input-icon {
  opacity: 1 !important;
}
.TextInput-component.has-error {
  border: 2px solid #FF5635 !important;
  -webkit-animation: shake 200ms forwards;
          animation: shake 200ms forwards;
  z-index: 3;
}
.TextInput-component.is-required {
  border: 2px solid #BF9046 !important;
  z-index: 2;
}
.TextInput-component.disabled {
  opacity: 0.5;
}
.TextInput-component .input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TextInput-component .input-icon.is-left {
  margin-left: 15px;
}
.TextInput-component .input-icon.is-right {
  margin-right: 15px;
}

.AddressLabel-component {
  position: relative;
}
.AddressLabel-component .expanded-address {
  word-break: break-all;
  text-align: left;
  opacity: 1;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  line-height: 150%;
}
.AddressLabel-component button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 2px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
  border-radius: 4px;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  will-change: opacity;
}
.AddressLabel-component button span {
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  font-weight: bold;
}
.is-light-theme .AddressLabel-component:hover .expanded-address,
.is-light-theme.AddressLabel-component:hover .expanded-address {
  opacity: 0.1;
}
.is-dark-theme .AddressLabel-component:hover .expanded-address,
.is-dark-theme.AddressLabel-component:hover .expanded-address {
  opacity: 0.2;
}
.AddressLabel-component:hover button {
  opacity: 1;
}

.AmountOverviewLabel-component .amount-overview-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 1fr;
}
.AmountOverviewLabel-component .amount-overview-grid > *:first-child {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
}
.AmountOverviewLabel-component .amount-overview-grid > *:first-child .Tooltip-component {
  margin-left: 5px;
}
.AmountOverviewLabel-component .amount-overview-grid > *:nth-child(2) {
  justify-self: center;
  font-size: 16px;
}
.AmountOverviewLabel-component .amount-overview-grid > *:last-child {
  justify-self: right;
  font-size: 14px;
  --tertiaryColorAlpha: 0.5;
}
.AmountOverviewLabel-component .separator-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.AmountOverviewLabel-component.fee-label-element {
  line-height: 130%;
}

.Separator-component {
  width: 100%;
  height: 1px;
  background-color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.is-light-theme .Separator-component,
.is-light-theme.Separator-component {
  --tertiaryColorAlpha: 0.1;
}
.is-dark-theme .Separator-component,
.is-dark-theme.Separator-component {
  --tertiaryColorAlpha: 0.3;
}
.Separator-component.vertical {
  width: 1px;
  height: 100%;
}

.LabelWithTooltip-component {
  display: inline-block;
}
.LabelWithTooltip-component .label-container {
  display: inline-block;
  font-weight: bold;
  position: relative;
  cursor: help;
}
.LabelWithTooltip-component .label-container:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background-image: linear-gradient(to right, rgba(var(--tertiaryColor), var(--tertiaryColorAlpha)) 3px, rgba(255, 255, 255, 0) 0%);
  background-size: 5px 2px;
  background-position: bottom;
  background-repeat: repeat-x;
}

[data-tooltip-context] {
  position: fixed;
  pointer-events: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.Tooltip-component {
  display: inline-block;
  pointer-events: all;
}
.Tooltip-component .tooltip-content {
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  padding: 15px 20px;
  width: auto;
  height: auto;
  border-radius: 7px;
  max-width: 250px;
  font-size: 14px;
}
.is-light-theme .Tooltip-component .tooltip-content,
.is-light-theme.Tooltip-component .tooltip-content {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.is-dark-theme .Tooltip-component .tooltip-content,
.is-dark-theme.Tooltip-component .tooltip-content {
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.4);
}
.Tooltip-component .tooltip-content .arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  margin: 5px;
}
.Tooltip-component .tooltip-content[x-placement^="top"] .arrow {
  border-width: 8px 8px 0 8px;
  border-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha)) transparent transparent transparent;
  bottom: -8px;
  left: calc(50% - 8px);
  margin-top: 0;
  margin-bottom: 0;
}
.Tooltip-component .tooltip-content[x-placement^="bottom"] .arrow {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent rgba(var(--secondaryColor), var(--secondaryColorAlpha)) transparent;
  top: -8px;
  left: calc(50% - 8px);
  margin-top: 0;
  margin-bottom: 0;
}
.Tooltip-component .tooltip-content[x-placement^="right"] .arrow {
  border-width: 8px 8px 8px 0;
  border-color: transparent rgba(var(--secondaryColor), var(--secondaryColorAlpha)) transparent transparent;
  left: -8px;
  top: calc(50% - 8px);
  margin-left: 0;
  margin-right: 0;
}
.Tooltip-component .tooltip-content[x-placement^="left"] .arrow {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  right: -8px;
  top: calc(50% - 8px);
  margin-left: 0;
  margin-right: 0;
}

.HelpIcon-component {
  margin: 1px 0;
  width: 18px;
  height: 18px;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  line-height: 18px !important;
  text-align: center;
  border-radius: 100%;
  cursor: help;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  --tertiaryColorAlpha: 0.4;
}
.HelpIcon-component:hover,
.HelpIcon-component:active {
  --tertiaryColorAlpha: 0.5;
}
.is-light-theme .HelpIcon-component,
.is-light-theme.HelpIcon-component {
  background-color: #EEEEEE;
}
.is-light-theme .HelpIcon-component:hover,
.is-light-theme.HelpIcon-component:hover,
.is-light-theme .HelpIcon-component:active,
.is-light-theme.HelpIcon-component:active {
  background-color: #BDBDBD;
}
.is-dark-theme .HelpIcon-component,
.is-dark-theme.HelpIcon-component {
  background-color: #212121;
}
.is-dark-theme .HelpIcon-component:hover,
.is-dark-theme.HelpIcon-component:hover,
.is-dark-theme .HelpIcon-component:active,
.is-dark-theme.HelpIcon-component:active {
  background-color: #757575;
}

.FlowActionHeadlineLabel-component {
  text-align: center;
}
.FlowActionHeadlineLabel-component .primary-label {
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.FlowActionHeadlineLabel-component .secondary-label {
  font-size: 18px;
  line-height: 22px;
  margin-top: 10px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.FlowActionHeadlineLabel-component .tertiary-label {
  font-size: 18px;
  line-height: 22px;
  margin-top: 6px;
  --tertiaryColorAlpha: 0.5;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}

.IncomingTxnLabel-component {
  display: flex;
  justify-content: center;
  align-items: center;
}
.IncomingTxnLabel-component .crypto-label,
.IncomingTxnLabel-component span,
.IncomingTxnLabel-component b {
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.IncomingTxnLabel-component .crypto-label,
.IncomingTxnLabel-component b {
  font-weight: bold;
}
.IncomingTxnLabel-component .crypto-label {
  display: inline-block;
  position: relative;
  cursor: help;
}
.IncomingTxnLabel-component .crypto-label:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background-image: linear-gradient(to right, rgba(var(--primaryColor), var(--primaryColorAlpha)) 3px, rgba(255, 255, 255, 0) 0%);
  background-size: 5px 2px;
  background-position: bottom;
  background-repeat: repeat-x;
}
.IncomingTxnLabel-component .spinner-container {
  margin-right: 10px;
  margin-top: 5px;
}

.SVGChevron-component.top {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.SVGChevron-component.bottom {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.SVGChevron-component.left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.SVGChevron-component.top {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.SVGChevron-component.bottom {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.SVGChevron-component.left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.SVGWave-component.animate {
  -webkit-animation: wave 1000ms ease-in-out infinite alternate;
          animation: wave 1000ms ease-in-out infinite alternate;
}
@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
}
@keyframes wave {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
}

.InsufficientBalanceLabel-component .insufficient-balance-label {
  text-align: center;
  font-size: 16px;
  line-height: 150%;
}
.InsufficientBalanceLabel-component .separator-container {
  margin-top: 20px;
  margin-bottom: 30px;
}
.InsufficientBalanceLabel-component .loading-label {
  --tertiaryColorAlpha: 0.5;
}

.PasswordValidationFeedback-component {
  font-size: 11px;
  line-height: 13px;
}
.PasswordValidationFeedback-component * {
  -webkit-transition: 200ms;
  transition: 200ms;
}
.PasswordValidationFeedback-component .requirement {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
}
.PasswordValidationFeedback-component .requirement .label-container {
  font-size: 11px;
  line-height: 13px;
  color: #757575;
  margin-left: 5px;
}
.PasswordValidationFeedback-component .requirement .label-container.is-valid {
  color: #00AC85;
}

.PendingTxnInfo-component .status-info {
  text-align: center;
}
.PendingTxnInfo-component .etherscan-label-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PendingTxnInfo-component .etherscan-label-container .etherscan-label {
  font-weight: bold;
  margin-right: 5px;
}

.ProgressBar-component {
  --iconSize: 22px;
  position: relative;
  height: var(--iconSize);
  width: 100%;
  overflow: hidden;
}
.ProgressBar-component .progress-indicator {
  position: absolute;
  height: 10px;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 7px;
  -webkit-transition: width 250ms;
  transition: width 250ms;
}
.ProgressBar-component .progress-indicator.underway {
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.ProgressBar-component .progress-indicator.success {
  background-color: #00C988;
}
.ProgressBar-component .progress-indicator.error {
  background-color: #FF5635;
}
.ProgressBar-component .progress-indicator.unknown {
  background-color: #757575;
}
.ProgressBar-component .progress-indicator.fiat {
  background-color: #EEEEEE;
}
.ProgressBar-component .progress-background {
  width: 100%;
  opacity: 0.5;
}
.ProgressBar-component .progress-foreground-container {
  position: absolute;
}
.ProgressBar-component .progress-foreground-container:not(.has-icon) {
  z-index: 2;
  width: 100%;
  height: 10px;
  border-radius: 7px;
  overflow: hidden;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ProgressBar-component .progress-foreground-container.has-icon {
  height: 100%;
  width: calc(100% - var(--iconSize));
}
.ProgressBar-component .progress-foreground {
  opacity: 1;
}
.ProgressBar-component .progress-foreground.fiat {
  background-color: #00C988;
}
.ProgressBar-component .progress-icon-container {
  position: absolute;
  width: var(--iconSize);
  height: var(--iconSize);
  top: 0;
  -webkit-transition: left 250ms;
  transition: left 250ms;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  border-width: 2px;
  border-style: solid;
}
.ProgressBar-component .progress-icon-container.underway {
  border-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.ProgressBar-component .progress-icon-container.success {
  border-color: #00C988;
}
.ProgressBar-component .progress-icon-container.error {
  border-color: #FF5635;
}
.ProgressBar-component .progress-icon-container.unknown {
  border-color: #757575;
}
.ProgressBar-component .progress-icon-container.fiat {
  border-color: #EEEEEE;
}
.ProgressBar-component .progress-icon-container .icon {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.ProgressBar-component .progress-icon-container .icon.checkmark,
.ProgressBar-component .progress-icon-container .icon.question-mark {
  padding: 1px;
}
.ProgressBar-component .progress-icon-container .icon.checkmark > div,
.ProgressBar-component .progress-icon-container .icon.question-mark > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ProgressBar-component .progress-icon-container .icon.rocket {
  display: block;
  padding: 3px;
}

.TransactionInfo-component.fee-explanation {
  max-width: 240px;
}
.TransactionInfo-component .transfer-from-grid {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 25% 75%;
  grid-template-rows: 1fr;
  grid-template-areas: 'label content';
}
.TransactionInfo-component .transfer-from-grid .label {
  display: flex;
  align-items: center;
  grid-area: label;
}
.TransactionInfo-component .transfer-from-grid .label,
.TransactionInfo-component .transfer-from-grid .label * {
  font-size: 16px;
  font-weight: bold;
  line-height: 130%;
  text-transform: uppercase;
}
.TransactionInfo-component .transfer-from-grid:not(:first-child) {
  margin-top: 15px;
}
.TransactionInfo-component .transfer-from-grid .content {
  grid-area: content;
  text-align: end;
}
.TransactionInfo-component .transfer-from-grid .content a {
  margin-left: 15px;
  font-weight: bold;
}
.TransactionInfo-component .transfer-from-grid .fee-label-container {
  align-self: center;
}
.TransactionInfo-component .transfer-from-grid .fee-label-container .fee-label {
  margin-right: 6px;
}
.TransactionInfo-component .transfer-from-grid .fee-button-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TransactionInfo-component .transfer-from-grid .fee-button-content > div:first-child {
  font-size: 16px;
  font-weight: normal;
}
.TransactionInfo-component .transfer-from-grid .fee-button-content > div:last-child {
  font-size: 14px;
  font-weight: normal;
  --tertiaryColorAlpha: 0.5;
}
.TransactionInfo-component .transfer-from-grid .address-content {
  padding: 0 10px;
}

.Checkbox-component {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.is-dark-theme .Checkbox-component:hover,
.is-dark-theme .Checkbox-component:active,
.is-dark-theme.Checkbox-component:hover,
.is-dark-theme.Checkbox-component:active {
  border: 1px solid rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-dark-theme .Checkbox-component:hover svg > path,
.is-dark-theme .Checkbox-component:active svg > path,
.is-dark-theme.Checkbox-component:hover svg > path,
.is-dark-theme.Checkbox-component:active svg > path {
  fill: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-light-theme .Checkbox-component:hover,
.is-light-theme .Checkbox-component:active,
.is-light-theme.Checkbox-component:hover,
.is-light-theme.Checkbox-component:active {
  border: 1px solid rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .Checkbox-component:hover svg > path,
.is-light-theme .Checkbox-component:active svg > path,
.is-light-theme.Checkbox-component:hover svg > path,
.is-light-theme.Checkbox-component:active svg > path {
  fill: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.Checkbox-component.checked {
  border: 1px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.Checkbox-component .svg-container {
  display: flex;
}
.Checkbox-component svg > path {
  fill: rgba(var(--primaryColor), var(--primaryColorAlpha));
}

.CTAButton-component {
  margin: 0 !important;
}
.is-dark-theme .CTAButton-component.is-primary,
.is-dark-theme.CTAButton-component.is-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
}
.is-dark-theme .CTAButton-component.is-primary *,
.is-dark-theme.CTAButton-component.is-primary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-primary.disabled,
.is-dark-theme.CTAButton-component.is-primary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-primary .button-label,
.is-dark-theme.CTAButton-component.is-primary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-primary .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-primary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-primary .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-primary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-primary .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-primary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-primary .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-primary .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-primary .button-label,
.is-dark-theme.CTAButton-component.is-primary .button-label,
.is-dark-theme .CTAButton-component.is-primary .button-label *,
.is-dark-theme.CTAButton-component.is-primary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-primary:hover,
.is-dark-theme.CTAButton-component.is-primary:hover {
  background-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-dark-theme .CTAButton-component.is-primary:active,
.is-dark-theme.CTAButton-component.is-primary:active {
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-dark-theme .CTAButton-component.is-primary:hover,
.is-dark-theme.CTAButton-component.is-primary:hover,
.is-dark-theme .CTAButton-component.is-primary:active,
.is-dark-theme.CTAButton-component.is-primary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-primary:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-primary:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-primary:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-primary:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-primary:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-primary:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-primary:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-primary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-primary:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-primary:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-primary:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-primary:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-primary:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-primary:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-primary:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-primary:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-secondary,
.is-dark-theme.CTAButton-component.is-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  background-color: transparent;
  border: solid 2px rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary *,
.is-dark-theme.CTAButton-component.is-secondary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-secondary.disabled,
.is-dark-theme.CTAButton-component.is-secondary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-secondary .button-label,
.is-dark-theme.CTAButton-component.is-secondary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-secondary .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-secondary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-secondary .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-secondary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-secondary .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-secondary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-secondary .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-secondary .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-secondary .button-label,
.is-dark-theme.CTAButton-component.is-secondary .button-label,
.is-dark-theme .CTAButton-component.is-secondary .button-label *,
.is-dark-theme.CTAButton-component.is-secondary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme.CTAButton-component.is-secondary:hover {
  background-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary:active,
.is-dark-theme.CTAButton-component.is-secondary:active {
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme.CTAButton-component.is-secondary:hover,
.is-dark-theme .CTAButton-component.is-secondary:active,
.is-dark-theme.CTAButton-component.is-secondary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-secondary:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-secondary:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-secondary:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-secondary:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-secondary:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-secondary:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-secondary:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-secondary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-secondary:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-secondary:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-secondary:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-secondary:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-secondary:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-secondary:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-secondary .button-label,
.is-dark-theme.CTAButton-component.is-secondary .button-label,
.is-dark-theme .CTAButton-component.is-secondary .button-label *,
.is-dark-theme.CTAButton-component.is-secondary .button-label * {
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme.CTAButton-component.is-secondary:hover,
.is-dark-theme .CTAButton-component.is-secondary:active,
.is-dark-theme.CTAButton-component.is-secondary:active {
  background-color: transparent;
}
.is-dark-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme.CTAButton-component.is-secondary:hover {
  border-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
  color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary:hover .button-label,
.is-dark-theme.CTAButton-component.is-secondary:hover .button-label,
.is-dark-theme .CTAButton-component.is-secondary:hover .button-label *,
.is-dark-theme.CTAButton-component.is-secondary:hover .button-label * {
  color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
  --CTAButtonContentColor: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary:active,
.is-dark-theme.CTAButton-component.is-secondary:active {
  border-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
  color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-dark-theme .CTAButton-component.is-secondary:active .button-label,
.is-dark-theme.CTAButton-component.is-secondary:active .button-label,
.is-dark-theme .CTAButton-component.is-secondary:active .button-label *,
.is-dark-theme.CTAButton-component.is-secondary:active .button-label * {
  color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
  --CTAButtonContentColor: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-dark-theme .CTAButton-component.is-tertiary,
.is-dark-theme.CTAButton-component.is-tertiary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #212121;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-dark-theme .CTAButton-component.is-tertiary *,
.is-dark-theme.CTAButton-component.is-tertiary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-tertiary.disabled,
.is-dark-theme.CTAButton-component.is-tertiary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-tertiary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-tertiary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-tertiary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-tertiary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-tertiary .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-tertiary .button-label,
.is-dark-theme .CTAButton-component.is-tertiary .button-label *,
.is-dark-theme.CTAButton-component.is-tertiary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-tertiary:hover {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-tertiary:active {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-tertiary:hover,
.is-dark-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-tertiary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-tertiary:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-tertiary:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-tertiary:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-tertiary:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-tertiary:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-tertiary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-tertiary:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-tertiary:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-tertiary:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-tertiary:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-tertiary .button-label,
.is-dark-theme .CTAButton-component.is-tertiary .button-label *,
.is-dark-theme.CTAButton-component.is-tertiary .button-label * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  font-size: 16px;
}
.is-dark-theme .CTAButton-component.is-tertiary.disabled,
.is-dark-theme.CTAButton-component.is-tertiary.disabled {
  opacity: 1;
  background-color: transparent;
}
.is-dark-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-tertiary:hover,
.is-dark-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-tertiary:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-dark-theme .CTAButton-component.is-ghost,
.is-dark-theme.CTAButton-component.is-ghost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-dark-theme .CTAButton-component.is-ghost *,
.is-dark-theme.CTAButton-component.is-ghost * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-ghost.disabled,
.is-dark-theme.CTAButton-component.is-ghost.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-ghost .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-ghost .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-ghost .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-ghost .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-ghost .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-ghost .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-ghost .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-ghost .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-ghost .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-ghost .button-label,
.is-dark-theme .CTAButton-component.is-ghost .button-label *,
.is-dark-theme.CTAButton-component.is-ghost .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-ghost:hover {
  background-color: #212121;
}
.is-dark-theme .CTAButton-component.is-ghost:active,
.is-dark-theme.CTAButton-component.is-ghost:active {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-ghost:hover,
.is-dark-theme .CTAButton-component.is-ghost:active,
.is-dark-theme.CTAButton-component.is-ghost:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-ghost:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-ghost:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-ghost:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-ghost:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-ghost:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-ghost:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-ghost:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-ghost:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-ghost:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-ghost:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-ghost:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-ghost:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-ghost:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-ghost:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-ghost .button-label,
.is-dark-theme .CTAButton-component.is-ghost .button-label *,
.is-dark-theme.CTAButton-component.is-ghost .button-label * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  font-size: 16px;
}
.is-dark-theme .CTAButton-component.is-ghost:active .button-label,
.is-dark-theme.CTAButton-component.is-ghost:active .button-label,
.is-dark-theme .CTAButton-component.is-ghost:active .button-label *,
.is-dark-theme.CTAButton-component.is-ghost:active .button-label * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.is-dark-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-ghost:hover,
.is-dark-theme .CTAButton-component.is-ghost:active,
.is-dark-theme.CTAButton-component.is-ghost:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-light-theme .CTAButton-component.is-primary,
.is-light-theme.CTAButton-component.is-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
}
.is-light-theme .CTAButton-component.is-primary *,
.is-light-theme.CTAButton-component.is-primary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-primary.disabled,
.is-light-theme.CTAButton-component.is-primary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-primary .button-label,
.is-light-theme.CTAButton-component.is-primary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-primary .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-primary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-primary .button-label .is-loading,
.is-light-theme.CTAButton-component.is-primary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-primary .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-primary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-primary .button-label .not-loading,
.is-light-theme.CTAButton-component.is-primary .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-primary .button-label,
.is-light-theme.CTAButton-component.is-primary .button-label,
.is-light-theme .CTAButton-component.is-primary .button-label *,
.is-light-theme.CTAButton-component.is-primary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-primary:hover,
.is-light-theme.CTAButton-component.is-primary:hover {
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-light-theme .CTAButton-component.is-primary:active,
.is-light-theme.CTAButton-component.is-primary:active {
  background-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .CTAButton-component.is-primary:hover,
.is-light-theme.CTAButton-component.is-primary:hover,
.is-light-theme .CTAButton-component.is-primary:active,
.is-light-theme.CTAButton-component.is-primary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-primary:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-primary:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-primary:active,
.is-light-theme .is-light-theme.CTAButton-component.is-primary:active,
.is-light-theme.is-light-theme .CTAButton-component.is-primary:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-primary:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-primary:active,
.is-light-theme.is-light-theme.CTAButton-component.is-primary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-primary:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-primary:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-primary:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-primary:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-primary:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-primary:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-primary:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-primary:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-secondary,
.is-light-theme.CTAButton-component.is-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  background-color: transparent;
  border: solid 2px rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.is-light-theme .CTAButton-component.is-secondary *,
.is-light-theme.CTAButton-component.is-secondary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-secondary.disabled,
.is-light-theme.CTAButton-component.is-secondary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-secondary .button-label,
.is-light-theme.CTAButton-component.is-secondary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-secondary .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-secondary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-secondary .button-label .is-loading,
.is-light-theme.CTAButton-component.is-secondary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-secondary .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-secondary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-secondary .button-label .not-loading,
.is-light-theme.CTAButton-component.is-secondary .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-secondary .button-label,
.is-light-theme.CTAButton-component.is-secondary .button-label,
.is-light-theme .CTAButton-component.is-secondary .button-label *,
.is-light-theme.CTAButton-component.is-secondary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-secondary:hover,
.is-light-theme.CTAButton-component.is-secondary:hover {
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-light-theme .CTAButton-component.is-secondary:active,
.is-light-theme.CTAButton-component.is-secondary:active {
  background-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .CTAButton-component.is-secondary:hover,
.is-light-theme.CTAButton-component.is-secondary:hover,
.is-light-theme .CTAButton-component.is-secondary:active,
.is-light-theme.CTAButton-component.is-secondary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-secondary:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-secondary:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-secondary:active,
.is-light-theme .is-light-theme.CTAButton-component.is-secondary:active,
.is-light-theme.is-light-theme .CTAButton-component.is-secondary:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-secondary:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-secondary:active,
.is-light-theme.is-light-theme.CTAButton-component.is-secondary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-secondary:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-secondary:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-secondary:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-secondary:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-secondary:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-secondary:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-secondary:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-secondary .button-label,
.is-light-theme.CTAButton-component.is-secondary .button-label,
.is-light-theme .CTAButton-component.is-secondary .button-label *,
.is-light-theme.CTAButton-component.is-secondary .button-label * {
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.is-light-theme .CTAButton-component.is-secondary:hover,
.is-light-theme.CTAButton-component.is-secondary:hover,
.is-light-theme .CTAButton-component.is-secondary:active,
.is-light-theme.CTAButton-component.is-secondary:active {
  background-color: transparent;
}
.is-light-theme .CTAButton-component.is-secondary:hover,
.is-light-theme.CTAButton-component.is-secondary:hover {
  border-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
  color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-light-theme .CTAButton-component.is-secondary:hover .button-label,
.is-light-theme.CTAButton-component.is-secondary:hover .button-label,
.is-light-theme .CTAButton-component.is-secondary:hover .button-label *,
.is-light-theme.CTAButton-component.is-secondary:hover .button-label * {
  color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
  --CTAButtonContentColor: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-light-theme .CTAButton-component.is-secondary:active,
.is-light-theme.CTAButton-component.is-secondary:active {
  border-color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
  color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .CTAButton-component.is-secondary:active .button-label,
.is-light-theme.CTAButton-component.is-secondary:active .button-label,
.is-light-theme .CTAButton-component.is-secondary:active .button-label *,
.is-light-theme.CTAButton-component.is-secondary:active .button-label * {
  color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
  --CTAButtonContentColor: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .CTAButton-component.is-tertiary,
.is-light-theme.CTAButton-component.is-tertiary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #EEEEEE;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-light-theme .CTAButton-component.is-tertiary *,
.is-light-theme.CTAButton-component.is-tertiary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-tertiary.disabled,
.is-light-theme.CTAButton-component.is-tertiary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-tertiary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-tertiary .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-tertiary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-tertiary .button-label .is-loading,
.is-light-theme.CTAButton-component.is-tertiary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-tertiary .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-tertiary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-tertiary .button-label .not-loading,
.is-light-theme.CTAButton-component.is-tertiary .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-tertiary .button-label,
.is-light-theme .CTAButton-component.is-tertiary .button-label *,
.is-light-theme.CTAButton-component.is-tertiary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-tertiary:hover {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-tertiary:active,
.is-light-theme.CTAButton-component.is-tertiary:active {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-tertiary:hover,
.is-light-theme .CTAButton-component.is-tertiary:active,
.is-light-theme.CTAButton-component.is-tertiary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-tertiary:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-tertiary:active,
.is-light-theme .is-light-theme.CTAButton-component.is-tertiary:active,
.is-light-theme.is-light-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-tertiary:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-tertiary:active,
.is-light-theme.is-light-theme.CTAButton-component.is-tertiary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-tertiary:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-tertiary:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-tertiary:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-tertiary:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-tertiary:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-tertiary:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-tertiary .button-label,
.is-light-theme .CTAButton-component.is-tertiary .button-label *,
.is-light-theme.CTAButton-component.is-tertiary .button-label * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  font-size: 16px;
}
.is-light-theme .CTAButton-component.is-tertiary.disabled,
.is-light-theme.CTAButton-component.is-tertiary.disabled {
  opacity: 1;
  background-color: transparent;
}
.is-light-theme .CTAButton-component.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-tertiary:hover,
.is-light-theme .CTAButton-component.is-tertiary:active,
.is-light-theme.CTAButton-component.is-tertiary:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-light-theme .CTAButton-component.is-ghost,
.is-light-theme.CTAButton-component.is-ghost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-light-theme .CTAButton-component.is-ghost *,
.is-light-theme.CTAButton-component.is-ghost * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-ghost.disabled,
.is-light-theme.CTAButton-component.is-ghost.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-ghost .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-ghost .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-ghost .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-ghost .button-label .is-loading,
.is-light-theme.CTAButton-component.is-ghost .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-ghost .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-ghost .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-ghost .button-label .not-loading,
.is-light-theme.CTAButton-component.is-ghost .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-ghost .button-label,
.is-light-theme .CTAButton-component.is-ghost .button-label *,
.is-light-theme.CTAButton-component.is-ghost .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-ghost:hover,
.is-light-theme.CTAButton-component.is-ghost:hover {
  background-color: #EEEEEE;
}
.is-light-theme .CTAButton-component.is-ghost:active,
.is-light-theme.CTAButton-component.is-ghost:active {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-ghost:hover,
.is-light-theme.CTAButton-component.is-ghost:hover,
.is-light-theme .CTAButton-component.is-ghost:active,
.is-light-theme.CTAButton-component.is-ghost:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-ghost:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-ghost:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-ghost:active,
.is-light-theme .is-light-theme.CTAButton-component.is-ghost:active,
.is-light-theme.is-light-theme .CTAButton-component.is-ghost:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-ghost:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-ghost:active,
.is-light-theme.is-light-theme.CTAButton-component.is-ghost:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-ghost:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-ghost:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-ghost:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-ghost:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-ghost:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-ghost:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-ghost:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-ghost .button-label,
.is-light-theme .CTAButton-component.is-ghost .button-label *,
.is-light-theme.CTAButton-component.is-ghost .button-label * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  font-size: 16px;
}
.is-light-theme .CTAButton-component.is-ghost:active .button-label,
.is-light-theme.CTAButton-component.is-ghost:active .button-label,
.is-light-theme .CTAButton-component.is-ghost:active .button-label *,
.is-light-theme.CTAButton-component.is-ghost:active .button-label * {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.is-light-theme .CTAButton-component.is-ghost:hover,
.is-light-theme.CTAButton-component.is-ghost:hover,
.is-light-theme .CTAButton-component.is-ghost:active,
.is-light-theme.CTAButton-component.is-ghost:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.CTAButton-component.is-success.is-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #00C988;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
}
.CTAButton-component.is-success.is-primary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.CTAButton-component.is-success.is-primary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.CTAButton-component.is-success.is-primary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CTAButton-component.is-success.is-primary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.CTAButton-component.is-success.is-primary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.CTAButton-component.is-success.is-primary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.CTAButton-component.is-success.is-primary .button-label .not-loading {
  display: none;
}
.CTAButton-component.is-success.is-primary .button-label,
.CTAButton-component.is-success.is-primary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.CTAButton-component.is-success.is-primary:hover {
  background-color: #5FEEA4;
}
.CTAButton-component.is-success.is-primary:active {
  background-color: #00AC85;
}
.CTAButton-component.is-success.is-primary:hover,
.CTAButton-component.is-success.is-primary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .CTAButton-component.is-success.is-primary:hover,
.is-light-theme .CTAButton-component.is-success.is-primary:active,
.is-light-theme.CTAButton-component.is-success.is-primary:hover,
.is-light-theme.CTAButton-component.is-success.is-primary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .CTAButton-component.is-success.is-primary:hover,
.is-dark-theme .CTAButton-component.is-success.is-primary:active,
.is-dark-theme.CTAButton-component.is-success.is-primary:hover,
.is-dark-theme.CTAButton-component.is-success.is-primary:active {
  --boxShadowDistance: 15px;
}
.CTAButton-component.is-success.is-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #00C988;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  background-color: transparent;
  border: solid 2px #00C988;
}
.CTAButton-component.is-success.is-secondary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.CTAButton-component.is-success.is-secondary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.CTAButton-component.is-success.is-secondary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CTAButton-component.is-success.is-secondary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.CTAButton-component.is-success.is-secondary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.CTAButton-component.is-success.is-secondary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.CTAButton-component.is-success.is-secondary .button-label .not-loading {
  display: none;
}
.CTAButton-component.is-success.is-secondary .button-label,
.CTAButton-component.is-success.is-secondary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.CTAButton-component.is-success.is-secondary:hover {
  background-color: #5FEEA4;
}
.CTAButton-component.is-success.is-secondary:active {
  background-color: #00AC85;
}
.CTAButton-component.is-success.is-secondary:hover,
.CTAButton-component.is-success.is-secondary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .CTAButton-component.is-success.is-secondary:hover,
.is-light-theme .CTAButton-component.is-success.is-secondary:active,
.is-light-theme.CTAButton-component.is-success.is-secondary:hover,
.is-light-theme.CTAButton-component.is-success.is-secondary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .CTAButton-component.is-success.is-secondary:hover,
.is-dark-theme .CTAButton-component.is-success.is-secondary:active,
.is-dark-theme.CTAButton-component.is-success.is-secondary:hover,
.is-dark-theme.CTAButton-component.is-success.is-secondary:active {
  --boxShadowDistance: 15px;
}
.CTAButton-component.is-success.is-secondary .button-label,
.CTAButton-component.is-success.is-secondary .button-label * {
  color: #00C988;
  --CTAButtonContentColor: #00C988;
}
.CTAButton-component.is-success.is-secondary:hover,
.CTAButton-component.is-success.is-secondary:active {
  background-color: transparent;
}
.CTAButton-component.is-success.is-secondary:hover {
  border-color: #5FEEA4;
  color: #5FEEA4;
}
.CTAButton-component.is-success.is-secondary:hover .button-label,
.CTAButton-component.is-success.is-secondary:hover .button-label * {
  color: #5FEEA4;
  --CTAButtonContentColor: #5FEEA4;
}
.CTAButton-component.is-success.is-secondary:active {
  border-color: #00AC85;
  color: #00AC85;
}
.CTAButton-component.is-success.is-secondary:active .button-label,
.CTAButton-component.is-success.is-secondary:active .button-label * {
  color: #00AC85;
  --CTAButtonContentColor: #00AC85;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary,
.is-dark-theme.CTAButton-component.is-success.is-tertiary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #212121;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary *,
.is-dark-theme.CTAButton-component.is-success.is-tertiary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary.disabled,
.is-dark-theme.CTAButton-component.is-success.is-tertiary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label,
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label *,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-success.is-tertiary:hover {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-success.is-tertiary:active {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-success.is-tertiary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-success.is-tertiary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-success.is-tertiary:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label,
.is-dark-theme .CTAButton-component.is-success.is-tertiary .button-label *,
.is-dark-theme.CTAButton-component.is-success.is-tertiary .button-label * {
  color: #5FEEA4;
  --CTAButtonContentColor: #5FEEA4;
  font-size: 16px;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary.disabled,
.is-dark-theme.CTAButton-component.is-success.is-tertiary.disabled {
  opacity: 1;
  background-color: transparent;
}
.is-dark-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-success.is-tertiary:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary,
.is-light-theme.CTAButton-component.is-success.is-tertiary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #EEEEEE;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary *,
.is-light-theme.CTAButton-component.is-success.is-tertiary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary.disabled,
.is-light-theme.CTAButton-component.is-success.is-tertiary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label .is-loading,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label .not-loading,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label,
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label *,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-success.is-tertiary:hover {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.CTAButton-component.is-success.is-tertiary:active {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.CTAButton-component.is-success.is-tertiary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme .is-light-theme.CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.is-light-theme.CTAButton-component.is-success.is-tertiary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-success.is-tertiary:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label,
.is-light-theme .CTAButton-component.is-success.is-tertiary .button-label *,
.is-light-theme.CTAButton-component.is-success.is-tertiary .button-label * {
  color: #00AC85;
  --CTAButtonContentColor: #00AC85;
  font-size: 16px;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary.disabled,
.is-light-theme.CTAButton-component.is-success.is-tertiary.disabled {
  opacity: 1;
  background-color: transparent;
}
.is-light-theme .CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-success.is-tertiary:hover,
.is-light-theme .CTAButton-component.is-success.is-tertiary:active,
.is-light-theme.CTAButton-component.is-success.is-tertiary:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost,
.is-dark-theme.CTAButton-component.is-success.is-ghost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost *,
.is-dark-theme.CTAButton-component.is-success.is-ghost * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost.disabled,
.is-dark-theme.CTAButton-component.is-success.is-ghost.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label,
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label *,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-success.is-ghost:hover {
  background-color: #212121;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.CTAButton-component.is-success.is-ghost:active {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.CTAButton-component.is-success.is-ghost:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-success.is-ghost:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-success.is-ghost:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-success.is-ghost:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-success.is-ghost:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label,
.is-dark-theme .CTAButton-component.is-success.is-ghost .button-label *,
.is-dark-theme.CTAButton-component.is-success.is-ghost .button-label * {
  color: #00C988;
  --CTAButtonContentColor: #00C988;
  font-size: 16px;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost:active .button-label,
.is-dark-theme.CTAButton-component.is-success.is-ghost:active .button-label,
.is-dark-theme .CTAButton-component.is-success.is-ghost:active .button-label *,
.is-dark-theme.CTAButton-component.is-success.is-ghost:active .button-label * {
  color: #5FEEA4;
  --CTAButtonContentColor: #5FEEA4;
}
.is-dark-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.CTAButton-component.is-success.is-ghost:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-light-theme .CTAButton-component.is-success.is-ghost,
.is-light-theme.CTAButton-component.is-success.is-ghost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-light-theme .CTAButton-component.is-success.is-ghost *,
.is-light-theme.CTAButton-component.is-success.is-ghost * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-success.is-ghost.disabled,
.is-light-theme.CTAButton-component.is-success.is-ghost.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label .is-loading,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label .not-loading,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label,
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label *,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.CTAButton-component.is-success.is-ghost:hover {
  background-color: #EEEEEE;
}
.is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme.CTAButton-component.is-success.is-ghost:active {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.CTAButton-component.is-success.is-ghost:hover,
.is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme.CTAButton-component.is-success.is-ghost:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-success.is-ghost:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme .is-light-theme.CTAButton-component.is-success.is-ghost:active,
.is-light-theme.is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme.is-light-theme.CTAButton-component.is-success.is-ghost:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-success.is-ghost:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-success.is-ghost:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label,
.is-light-theme .CTAButton-component.is-success.is-ghost .button-label *,
.is-light-theme.CTAButton-component.is-success.is-ghost .button-label * {
  color: #00C988;
  --CTAButtonContentColor: #00C988;
  font-size: 16px;
}
.is-light-theme .CTAButton-component.is-success.is-ghost:active .button-label,
.is-light-theme.CTAButton-component.is-success.is-ghost:active .button-label,
.is-light-theme .CTAButton-component.is-success.is-ghost:active .button-label *,
.is-light-theme.CTAButton-component.is-success.is-ghost:active .button-label * {
  color: #00AC85;
  --CTAButtonContentColor: #00AC85;
}
.is-light-theme .CTAButton-component.is-success.is-ghost:hover,
.is-light-theme.CTAButton-component.is-success.is-ghost:hover,
.is-light-theme .CTAButton-component.is-success.is-ghost:active,
.is-light-theme.CTAButton-component.is-success.is-ghost:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.CTAButton-component.is-danger.is-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #FF5635;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
}
.CTAButton-component.is-danger.is-primary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.CTAButton-component.is-danger.is-primary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.CTAButton-component.is-danger.is-primary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CTAButton-component.is-danger.is-primary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.CTAButton-component.is-danger.is-primary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.CTAButton-component.is-danger.is-primary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.CTAButton-component.is-danger.is-primary .button-label .not-loading {
  display: none;
}
.CTAButton-component.is-danger.is-primary .button-label,
.CTAButton-component.is-danger.is-primary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.CTAButton-component.is-danger.is-primary:hover {
  background-color: #FF5635;
}
.CTAButton-component.is-danger.is-primary:active {
  background-color: #D02F20;
}
.CTAButton-component.is-danger.is-primary:hover,
.CTAButton-component.is-danger.is-primary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .CTAButton-component.is-danger.is-primary:hover,
.is-light-theme .CTAButton-component.is-danger.is-primary:active,
.is-light-theme.CTAButton-component.is-danger.is-primary:hover,
.is-light-theme.CTAButton-component.is-danger.is-primary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .CTAButton-component.is-danger.is-primary:hover,
.is-dark-theme .CTAButton-component.is-danger.is-primary:active,
.is-dark-theme.CTAButton-component.is-danger.is-primary:hover,
.is-dark-theme.CTAButton-component.is-danger.is-primary:active {
  --boxShadowDistance: 15px;
}
.CTAButton-component.is-danger.is-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #FF5635;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  background-color: transparent;
  border: solid 2px #FF5635;
}
.CTAButton-component.is-danger.is-secondary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.CTAButton-component.is-danger.is-secondary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.CTAButton-component.is-danger.is-secondary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CTAButton-component.is-danger.is-secondary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.CTAButton-component.is-danger.is-secondary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.CTAButton-component.is-danger.is-secondary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.CTAButton-component.is-danger.is-secondary .button-label .not-loading {
  display: none;
}
.CTAButton-component.is-danger.is-secondary .button-label,
.CTAButton-component.is-danger.is-secondary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.CTAButton-component.is-danger.is-secondary:hover {
  background-color: #FF5635;
}
.CTAButton-component.is-danger.is-secondary:active {
  background-color: #D02F20;
}
.CTAButton-component.is-danger.is-secondary:hover,
.CTAButton-component.is-danger.is-secondary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .CTAButton-component.is-danger.is-secondary:hover,
.is-light-theme .CTAButton-component.is-danger.is-secondary:active,
.is-light-theme.CTAButton-component.is-danger.is-secondary:hover,
.is-light-theme.CTAButton-component.is-danger.is-secondary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .CTAButton-component.is-danger.is-secondary:hover,
.is-dark-theme .CTAButton-component.is-danger.is-secondary:active,
.is-dark-theme.CTAButton-component.is-danger.is-secondary:hover,
.is-dark-theme.CTAButton-component.is-danger.is-secondary:active {
  --boxShadowDistance: 15px;
}
.CTAButton-component.is-danger.is-secondary .button-label,
.CTAButton-component.is-danger.is-secondary .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
}
.CTAButton-component.is-danger.is-secondary:hover,
.CTAButton-component.is-danger.is-secondary:active {
  background-color: transparent;
}
.CTAButton-component.is-danger.is-secondary:hover {
  border-color: #FF5635;
  color: #FF5635;
}
.CTAButton-component.is-danger.is-secondary:hover .button-label,
.CTAButton-component.is-danger.is-secondary:hover .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
}
.CTAButton-component.is-danger.is-secondary:active {
  border-color: #D02F20;
  color: #D02F20;
}
.CTAButton-component.is-danger.is-secondary:active .button-label,
.CTAButton-component.is-danger.is-secondary:active .button-label * {
  color: #D02F20;
  --CTAButtonContentColor: #D02F20;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #212121;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary *,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary.disabled,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label,
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label *,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary:active {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-danger.is-tertiary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-danger.is-tertiary:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label,
.is-dark-theme .CTAButton-component.is-danger.is-tertiary .button-label *,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
  font-size: 16px;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary.disabled,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary.disabled {
  opacity: 1;
  background-color: transparent;
}
.is-dark-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.CTAButton-component.is-danger.is-tertiary:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary,
.is-light-theme.CTAButton-component.is-danger.is-tertiary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: #EEEEEE;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary *,
.is-light-theme.CTAButton-component.is-danger.is-tertiary * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary.disabled,
.is-light-theme.CTAButton-component.is-danger.is-tertiary.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label .is-loading,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label .not-loading,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label,
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label *,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-danger.is-tertiary:hover {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.CTAButton-component.is-danger.is-tertiary:active {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.CTAButton-component.is-danger.is-tertiary:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme .is-light-theme.CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.is-light-theme.CTAButton-component.is-danger.is-tertiary:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-danger.is-tertiary:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label,
.is-light-theme .CTAButton-component.is-danger.is-tertiary .button-label *,
.is-light-theme.CTAButton-component.is-danger.is-tertiary .button-label * {
  color: #D02F20;
  --CTAButtonContentColor: #D02F20;
  font-size: 16px;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary.disabled,
.is-light-theme.CTAButton-component.is-danger.is-tertiary.disabled {
  opacity: 1;
  background-color: transparent;
}
.is-light-theme .CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme.CTAButton-component.is-danger.is-tertiary:hover,
.is-light-theme .CTAButton-component.is-danger.is-tertiary:active,
.is-light-theme.CTAButton-component.is-danger.is-tertiary:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost,
.is-dark-theme.CTAButton-component.is-danger.is-ghost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost *,
.is-dark-theme.CTAButton-component.is-danger.is-ghost * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost.disabled,
.is-dark-theme.CTAButton-component.is-danger.is-ghost.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label > :not(:first-child),
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label .is-loading,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label .is-loading *,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label .not-loading,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label .not-loading {
  display: none;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label,
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label *,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:hover {
  background-color: #212121;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:active {
  background-color: #545454;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme .is-dark-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme .is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme .is-dark-theme.CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.is-dark-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.is-dark-theme.CTAButton-component.is-danger.is-ghost:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme .is-dark-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme .is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme .is-dark-theme.CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.is-dark-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.is-dark-theme.CTAButton-component.is-danger.is-ghost:active {
  --boxShadowDistance: 15px;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label,
.is-dark-theme .CTAButton-component.is-danger.is-ghost .button-label *,
.is-dark-theme.CTAButton-component.is-danger.is-ghost .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
  font-size: 16px;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost:active .button-label,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:active .button-label,
.is-dark-theme .CTAButton-component.is-danger.is-ghost:active .button-label *,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:active .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
}
.is-dark-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.CTAButton-component.is-danger.is-ghost:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost,
.is-light-theme.CTAButton-component.is-danger.is-ghost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform;
  box-sizing: border-box;
  will-change: transform, color, background-color, border-color;
  height: 38px;
  padding: 0 10px;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost *,
.is-light-theme.CTAButton-component.is-danger.is-ghost * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost.disabled,
.is-light-theme.CTAButton-component.is-danger.is-ghost.disabled {
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
  pointer-events: none;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label > :not(:first-child),
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label > :not(:first-child) {
  margin-left: 10px;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label .is-loading,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label .is-loading {
  margin-right: 8px;
  /* Unset parent transition*/
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label .is-loading *,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label .is-loading * {
  -webkit-transition: unset;
  transition: unset;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label .not-loading,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label .not-loading {
  display: none;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label,
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label *,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label * {
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --CTAButtonContentColor: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  white-space: nowrap;
  font-weight: 500;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.CTAButton-component.is-danger.is-ghost:hover {
  background-color: #EEEEEE;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.CTAButton-component.is-danger.is-ghost:active {
  background-color: #BDBDBD;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.CTAButton-component.is-danger.is-ghost:active {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 2px 2px var(--boxShadowDistance) 0 rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  --tertiaryColorAlpha: 0.4;
}
.is-light-theme .is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme .is-light-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme .is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme .is-light-theme.CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.is-light-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.is-light-theme.CTAButton-component.is-danger.is-ghost:active {
  --boxShadowDistance: 10px;
}
.is-dark-theme .is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme .is-light-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme .is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme .is-light-theme.CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.is-light-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-dark-theme.is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-dark-theme.is-light-theme.CTAButton-component.is-danger.is-ghost:active {
  --boxShadowDistance: 15px;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label,
.is-light-theme .CTAButton-component.is-danger.is-ghost .button-label *,
.is-light-theme.CTAButton-component.is-danger.is-ghost .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
  font-size: 16px;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost:active .button-label,
.is-light-theme.CTAButton-component.is-danger.is-ghost:active .button-label,
.is-light-theme .CTAButton-component.is-danger.is-ghost:active .button-label *,
.is-light-theme.CTAButton-component.is-danger.is-ghost:active .button-label * {
  color: #FF5635;
  --CTAButtonContentColor: #FF5635;
}
.is-light-theme .CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme.CTAButton-component.is-danger.is-ghost:hover,
.is-light-theme .CTAButton-component.is-danger.is-ghost:active,
.is-light-theme.CTAButton-component.is-danger.is-ghost:active {
  -webkit-transform: unset;
          transform: unset;
  box-shadow: unset;
  --tertiaryColorAlpha: 1;
}

.RadioButton-component {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.is-dark-theme .RadioButton-component:hover,
.is-dark-theme .RadioButton-component:active,
.is-dark-theme.RadioButton-component:hover,
.is-dark-theme.RadioButton-component:active {
  border: 1px solid rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-dark-theme .RadioButton-component:hover svg > path,
.is-dark-theme .RadioButton-component:active svg > path,
.is-dark-theme.RadioButton-component:hover svg > path,
.is-dark-theme.RadioButton-component:active svg > path {
  fill: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.is-light-theme .RadioButton-component:hover,
.is-light-theme .RadioButton-component:active,
.is-light-theme.RadioButton-component:hover,
.is-light-theme.RadioButton-component:active {
  border: 1px solid rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-light-theme .RadioButton-component:hover svg > path,
.is-light-theme .RadioButton-component:active svg > path,
.is-light-theme.RadioButton-component:hover svg > path,
.is-light-theme.RadioButton-component:active svg > path {
  fill: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.RadioButton-component.selected {
  border: 1px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.RadioButton-component .svg-container {
  display: flex;
}
.RadioButton-component svg > circle {
  fill: rgba(var(--primaryColor), var(--primaryColorAlpha));
}

.CTADescriptiveButton-component {
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.CTADescriptiveButton-component.network-border {
  border: 2px solid #BF9046;
}
.is-light-theme .CTADescriptiveButton-component,
.is-light-theme.CTADescriptiveButton-component {
  background-color: #EEEEEE;
}
.is-light-theme .CTADescriptiveButton-component:hover:not(.is-disabled),
.is-light-theme.CTADescriptiveButton-component:hover:not(.is-disabled) {
  background-color: #BDBDBD;
}
.is-dark-theme .CTADescriptiveButton-component,
.is-dark-theme.CTADescriptiveButton-component {
  background-color: #212121;
}
.is-dark-theme .CTADescriptiveButton-component:hover:not(.is-disabled),
.is-dark-theme.CTADescriptiveButton-component:hover:not(.is-disabled) {
  background-color: #545454;
}
.CTADescriptiveButton-component .spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.CTADescriptiveButton-component .button-content {
  display: flex;
  padding: 15px;
  align-items: center;
}
.CTADescriptiveButton-component .button-content.append-padding {
  padding-bottom: 25px;
}
.CTADescriptiveButton-component .button-content.is-disabled {
  opacity: 0.3;
  cursor: auto;
}
.CTADescriptiveButton-component .button-content.font-description {
  text-align: start;
}
.CTADescriptiveButton-component .button-content .image-wrapper {
  display: flex;
}
.CTADescriptiveButton-component .button-content .image-wrapper img,
.CTADescriptiveButton-component .button-content .image-wrapper svg {
  align-self: center;
  margin: 0 auto;
}
.CTADescriptiveButton-component .button-content .image-wrapper.sub {
  margin-right: 10px;
}
.CTADescriptiveButton-component .button-content .image-wrapper.main {
  margin-right: 15px;
  min-width: 36px;
}
.CTADescriptiveButton-component .button-content b {
  letter-spacing: -0.5px;
}

.BatchRequestCardStack-component {
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  width: calc(100% - (10px * 2));
}
.BatchRequestCardStack-component .background-stack {
  margin: 0 auto;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  --tertiaryColorAlpha: 0.15;
  box-shadow: 0px 0px 30px rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.BatchRequestCardStack-component .stack-1 {
  width: 80%;
  height: 15px;
  z-index: 99;
  opacity: 0.9;
}
.BatchRequestCardStack-component .stack-2 {
  width: 64%;
  height: 13px;
  z-index: 98;
  opacity: 0.8;
}
.BatchRequestCardStack-component .stack-3 {
  width: 51.2%;
  height: 11px;
  z-index: 97;
  opacity: 0.7;
}
.BatchRequestCardStack-component .stack-4 {
  width: 40.1%;
  height: 9px;
  z-index: 96;
  opacity: 0.6;
}
.BatchRequestCardStack-component .stack-actions {
  color: #757575 !important;
  font-weight: 500;
  margin: 10px auto 0 auto;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}
.BatchRequestCardStack-component .stack-actions a {
  font-size: 11px;
  line-height: 13px;
}
.is-light-theme .BatchRequestCardStack-component .stack-actions a,
.is-light-theme.BatchRequestCardStack-component .stack-actions a {
  color: rgba(var(--primaryColorDarker), var(--primaryColorDarkerAlpha));
}
.is-dark-theme .BatchRequestCardStack-component .stack-actions a,
.is-dark-theme.BatchRequestCardStack-component .stack-actions a {
  color: rgba(var(--secondaryColorLighter), var(--secondaryColorLighterAlpha)) !important;
}

.ButtonLabel-component {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  line-height: 20px !important;
  padding: 0 10px;
  text-align: center;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  align-self: flex-end;
  justify-self: flex-end;
  white-space: nowrap;
}
.ButtonLabel-component img {
  margin-right: 5px;
  height: 10px;
  width: auto;
}

.QRCode-component {
  display: flex;
  box-sizing: border-box;
}
.QRCode-component .qrcode-container {
  display: flex;
  box-shadow: 0 0 0 3px rgba(var(--secondaryColor), var(--secondaryColorAlpha)), 0 0 0 6px rgba(var(--primaryColor), var(--primaryColorAlpha));
  border-radius: 7px;
  background-color: #ffffff;
}
.QRCode-component .qrcode-container .qrcode {
  margin: calc(18px - 3px);
}

.ErrorMessage-component {
  margin-top: 8px;
  color: #FF5635;
  line-height: 130%;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.ErrorMessage-component a {
  color: #FF5635;
  -webkit-text-decoration: underline #FF5635;
          text-decoration: underline #FF5635;
  font-size: 14px;
}

.RequiredField-component {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #BF9046;
}

.BorderLabel-component {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.BorderLabel-component .label {
  padding: 0 4px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-size: 10px;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 15px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: center;
}

.PasswordInput-component.cta-show-or-hide-container {
  cursor: pointer;
}
.PasswordInput-component.cta-show-or-hide-container .cta-show-or-hide {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: 200ms;
  transition: 200ms;
  will-change: transform;
}
.PasswordInput-component.cta-show-or-hide-container .cta-show-or-hide.closed {
  margin-top: 2px;
}
.PasswordInput-component.cta-show-or-hide-container .cta-show-or-hide:hover,
.PasswordInput-component.cta-show-or-hide-container .cta-show-or-hide:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.AuthCodeInput-component {
  --border: 2px solid #757575;
  --border-selected: 2px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  cursor: text;
}
.AuthCodeInput-component.shake {
  -webkit-animation: 0.5s error-shake 1 ease-in-out;
          animation: 0.5s error-shake 1 ease-in-out;
}
.AuthCodeInput-component.disabled {
  opacity: 0.5;
}
.AuthCodeInput-component .verify-input-styling {
  height: 100%;
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AuthCodeInput-component .verify-input-styling.has-gutter {
  margin-right: 15px;
}
.AuthCodeInput-component .verify-input-styling * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.AuthCodeInput-component .verify-input-styling .separator {
  height: 100%;
  width: 2px;
  background-color: #757575;
  flex-shrink: 0;
}
.AuthCodeInput-component .verify-input-styling .separator.selected {
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.AuthCodeInput-component .verify-input-styling .styled-input {
  min-width: 25px;
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  height: 100%;
  /* Blinking cursor */
}
.AuthCodeInput-component .verify-input-styling .styled-input.orphan {
  border-radius: 7px;
  border: var(--border);
}
.AuthCodeInput-component .verify-input-styling .styled-input.orphan.selected {
  border: var(--border-selected);
}
.AuthCodeInput-component .verify-input-styling .styled-input.left {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top: var(--border);
  border-left: var(--border);
  border-bottom: var(--border);
}
.AuthCodeInput-component .verify-input-styling .styled-input.left.selected {
  border-top: var(--border-selected);
  border-left: var(--border-selected);
  border-bottom: var(--border-selected);
}
.AuthCodeInput-component .verify-input-styling .styled-input.intermediate {
  border-top: var(--border);
  border-bottom: var(--border);
}
.AuthCodeInput-component .verify-input-styling .styled-input.intermediate.selected {
  border-top: var(--border-selected);
  border-bottom: var(--border-selected);
}
.AuthCodeInput-component .verify-input-styling .styled-input.right {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: var(--border);
  border-right: var(--border);
  border-bottom: var(--border);
}
.AuthCodeInput-component .verify-input-styling .styled-input.right.selected {
  border-top: var(--border-selected);
  border-right: var(--border-selected);
  border-bottom: var(--border-selected);
}
.AuthCodeInput-component .verify-input-styling .styled-input.orphan.selected:before,
.AuthCodeInput-component .verify-input-styling .styled-input.left.selected:before,
.AuthCodeInput-component .verify-input-styling .styled-input.intermediate.selected:before,
.AuthCodeInput-component .verify-input-styling .styled-input.right.selected:before {
  content: "";
  border: 0.5px solid rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  position: absolute;
  height: 70%;
  top: 15%;
  left: 6px;
  opacity: 1;
  -webkit-animation: 1.2s blinking infinite;
          animation: 1.2s blinking infinite;
}
@-webkit-keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.AuthCodeInput-component .verify-input-styling .styled-input:after {
  content: attr(data-value);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}

.CurrencyInput-component .currency-input-label {
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px;
  width: 40px;
  height: 27px;
  border-radius: 7px;
  line-height: 27px;
  text-align: center;
}
.CurrencyInput-component .enable {
  background: #BDBDBD;
  color: white;
  cursor: pointer;
}
.CurrencyInput-component .input-icon.is-left {
  margin-left: 0;
}
.CurrencyInput-component .input-icon.is-right {
  margin-right: 0;
}

.PrimerScreenBlock-component {
  /* 
     * Offsets default page padding with Secured by Fortmatic footer
     */
  margin-bottom: -55px;
}
.PrimerScreenBlock-component .description {
  font-size: 21px;
  text-align: center;
  line-height: 130%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  margin-bottom: 30px;
}
.PrimerScreenBlock-component .feature-callout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 30px;
}
.PrimerScreenBlock-component .feature-callout > *:first-child {
  margin-right: 20px;
  flex-shrink: 0;
}
.PrimerScreenBlock-component .feature-callout > *:last-child {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.PrimerScreenBlock-component .terms-of-service {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  margin-top: 20px;
  text-align: center;
}
.PrimerScreenBlock-component .terms-of-service span,
.PrimerScreenBlock-component .terms-of-service a {
  font-size: 12px;
}
.is-dark-theme .PrimerScreenBlock-component .terms-of-service a,
.is-dark-theme.PrimerScreenBlock-component .terms-of-service a {
  color: #BDBDBD;
}
.is-dark-theme .PrimerScreenBlock-component .terms-of-service a:hover,
.is-dark-theme.PrimerScreenBlock-component .terms-of-service a:hover {
  -webkit-text-decoration-color: #BDBDBD;
          text-decoration-color: #BDBDBD;
}
.is-light-theme .PrimerScreenBlock-component .terms-of-service a,
.is-light-theme.PrimerScreenBlock-component .terms-of-service a {
  color: #757575;
}
.is-light-theme .PrimerScreenBlock-component .terms-of-service a:hover,
.is-light-theme.PrimerScreenBlock-component .terms-of-service a:hover {
  -webkit-text-decoration-color: #757575;
          text-decoration-color: #757575;
}
.PrimerScreenBlock-component .hide-future-primer-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.PrimerScreenBlock-component .hide-future-primer-prompt > *:first-child {
  margin-right: 13px;
}
.PrimerScreenBlock-component .hide-future-primer-prompt > *:last-child {
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}

.WelcomeScreenBlock-component .greeting-label {
  margin-top: 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.WelcomeScreenBlock-component .description {
  margin-top: 15px;
  font-size: 18px;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}

.TxnOverviewBottomBlock-component .cta-button-container {
  margin-top: 30px;
}
.TxnOverviewBottomBlock-component .transfer-module-container {
  margin-top: 35px;
}
.TxnOverviewBottomBlock-component .progress-bar-container {
  margin-top: 20px;
}
.TxnOverviewBottomBlock-component .pending-txn-info-container {
  margin-top: 16px;
}
.TxnOverviewBottomBlock-component .recovery-email-container {
  margin-top: 29px;
}
.TxnOverviewBottomBlock-component .txn-complete-cta-button-container {
  margin-top: 29px;
}
.TxnOverviewBottomBlock-component .txn-complete-cta-button-container.has-recovery-email-button {
  margin-top: 20px;
}
.TxnOverviewBottomBlock-component .generic-handle-send-error {
  margin-top: 20px;
  text-align: center;
}

.TransactionFeeSelectBlock-component .fee-button {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'label amount-crypto' 'time  amount-fiat';
  padding: 20px;
  height: 87px;
  box-sizing: border-box;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 7px;
}
.is-light-theme .TransactionFeeSelectBlock-component .fee-button,
.is-light-theme.TransactionFeeSelectBlock-component .fee-button {
  border: 2px solid #545454;
}
.is-dark-theme .TransactionFeeSelectBlock-component .fee-button,
.is-dark-theme.TransactionFeeSelectBlock-component .fee-button {
  border: 2px solid #BDBDBD;
}
.TransactionFeeSelectBlock-component .fee-button:hover,
.TransactionFeeSelectBlock-component .fee-button.selected {
  border: 2px solid rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.TransactionFeeSelectBlock-component .fee-button.selected {
  --primaryColorLighterAlpha: 0.2;
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.TransactionFeeSelectBlock-component .fee-button:active {
  --primaryColorLighterAlpha: 0.4;
  background-color: rgba(var(--primaryColorLighter), var(--primaryColorLighterAlpha));
}
.TransactionFeeSelectBlock-component .fee-button:not(:last-child) {
  margin-bottom: 15px;
}
.TransactionFeeSelectBlock-component .fee-button .fee-label {
  grid-area: label;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
}
.TransactionFeeSelectBlock-component .fee-button .fee-estimated-time {
  grid-area: time;
  font-size: 14px;
  line-height: 17px;
  align-self: flex-end;
}
.TransactionFeeSelectBlock-component .fee-button .fee-amount-crypto {
  grid-area: amount-crypto;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  align-self: flex-end;
}
.TransactionFeeSelectBlock-component .fee-button .fee-amount-fiat {
  grid-area: amount-fiat;
  font-size: 16px;
  text-align: right;
  align-self: flex-end;
  --tertiaryColorAlpha: 0.6;
}
.TransactionFeeSelectBlock-component .fee-explain-link {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  display: block;
  margin-bottom: 20px;
}

.FeeExplainerScreenBlock-component .content {
  font-size: 17px;
  line-height: 150%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.FeeExplainerScreenBlock-component .content b {
  font-weight: bold;
}
.FeeExplainerScreenBlock-component .cta-container {
  margin-top: 40px;
}
.FeeExplainerScreenBlock-component img.eth-gas-animation {
  display: block;
  margin: 25px 0;
  width: 100%;
}

.CryptoOnRampBlock-component .deposit-description {
  line-height: 150%;
  text-align: center;
}
.CryptoOnRampBlock-component .deposit-description b {
  font-weight: bold;
}
.CryptoOnRampBlock-component .address-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.CryptoOnRampBlock-component .address-action .your-address {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.CryptoOnRampBlock-component .address-action .address-label-container {
  margin-top: 5px;
  max-width: 205px;
}
.CryptoOnRampBlock-component .QRCode-component {
  justify-content: center;
  margin-top: 25px;
}
.CryptoOnRampBlock-component .incoming-txn-label-container {
  margin-bottom: 25px;
}
.CryptoOnRampBlock-component .incoming-txn-label-container :not(:first-child) {
  margin-top: 5px;
}

.DepositBlock-component .deposit-title {
  margin-bottom: 15px;
}
.DepositBlock-component .deposit-title div {
  text-align: center;
}
.DepositBlock-component .deposit-title div > b {
  font-weight: bold;
}
.DepositBlock-component .onRamp-selector > div:not(:last-child) {
  margin-bottom: 15px;
}
.applePay-button-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.applePay-button-content > div {
  line-height: 28px;
}
.applePay-button-content .applePay-button-labels {
  display: flex;
  flex-direction: column;
  /* To workaround fixed button 15px padding design */
  position: absolute;
  right: 15px;
  top: 10px;
  font-weight: bold;
}
.applePay-button-content .applePay-button-labels > :not(:first-child) {
  margin-top: 3px;
}

.FiatOnRampOrderSuccessBlock-component .success-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FiatOnRampOrderSuccessBlock-component .success-header img {
  width: 140px;
  height: 108px;
}
.FiatOnRampOrderSuccessBlock-component .success-header .font-greeting-label {
  margin-top: 20px;
}
.FiatOnRampOrderSuccessBlock-component .success-header .font-description {
  margin-top: 5px;
}
.FiatOnRampOrderSuccessBlock-component .order-detail-container {
  margin-top: 30px;
}
.FiatOnRampOrderSuccessBlock-component .order-detail-container > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.FiatOnRampOrderSuccessBlock-component .CTAButton-wrapper {
  margin-top: 30px;
}
.FiatOnRampOrderSuccessBlock-component .etherscan-label-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FiatOnRampOrderSuccessBlock-component .etherscan-label-container .etherscan-label {
  font-weight: bold;
  margin-right: 5px;
}

.FiatOnRampWhatIsMoonpay-component > [class*="font"] {
  text-align: start;
}
.FiatOnRampWhatIsMoonpay-component .compliant-item {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-items: flex-start;
}
.FiatOnRampWhatIsMoonpay-component .compliant-item svg {
  margin-top: 2px;
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.FiatOnRampWhatIsMoonpay-component .CTAButton-wrapper {
  margin-top: 35px;
}

.iframe-moonpay {
  height: 100%;
  width: 100%;
}
.iframe-moonpay iframe {
  border-width: 0;
  height: 500px;
}

.SendTxnTopBlock-component .transfer-module-container {
  margin-top: 35px;
}
.SendTxnTopBlock-component .incoming-txn-label-container {
  margin-bottom: 25px;
}
.SendTxnTopBlock-component .incoming-txn-label-container :not(:first-child) {
  margin-top: 5px;
}

.ComposeSendOverviewBlock-component .send-label-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: -15px;
}
.ComposeSendOverviewBlock-component .send-label-container .send-label {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}
.ComposeSendOverviewBlock-component .send-label-container .send-max {
  font-weight: 600;
  font-size: 12px;
}
.ComposeSendOverviewBlock-component .currency-converters {
  display: flex;
}
.ComposeSendOverviewBlock-component .currency-converters .CurrencyInput-component {
  flex-grow: 1;
}
.ComposeSendOverviewBlock-component .currency-converters .approx {
  font-size: 21px;
  line-height: 43px;
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.is-dark-theme .ComposeSendOverviewBlock-component .currency-converters .approx,
.is-dark-theme.ComposeSendOverviewBlock-component .currency-converters .approx {
  --tertiaryColorAlpha: 0.6;
}
.is-light-theme .ComposeSendOverviewBlock-component .currency-converters .approx,
.is-light-theme.ComposeSendOverviewBlock-component .currency-converters .approx {
  --tertiaryColorAlpha: 0.3;
}
.ComposeSendOverviewBlock-component .dest-address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}
.ComposeSendOverviewBlock-component .dest-address .to-label {
  flex-basis: 25%;
  font-weight: 700;
  line-height: 45px;
}
.ComposeSendOverviewBlock-component .dest-address .dest-address-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 75%;
}
.ComposeSendOverviewBlock-component .dest-address .dest-address-input .TextInput-component * {
  font-family: 'SourceCodePro';
}
.ComposeSendOverviewBlock-component .transfer-module-container {
  margin-top: 25px;
  margin-bottom: 20px;
}
.ComposeSendOverviewBlock-component .error-message {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
}
.ComposeSendOverviewBlock-component .required-field {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #BF9046;
}

.GenericContractTopBlock-component .transfer-module-container {
  margin-top: 35px;
}
.GenericContractTopBlock-component .incoming-txn-label-container {
  margin-bottom: 25px;
}
.GenericContractTopBlock-component .incoming-txn-label-container :not(:first-child) {
  margin-top: 5px;
}

.ContractApproveTopBlock-component .transfer-module-container {
  margin-top: 35px;
}
.ContractApproveTopBlock-component .incoming-txn-label-container {
  margin-bottom: 25px;
}
.ContractApproveTopBlock-component .incoming-txn-label-container :not(:first-child) {
  margin-top: 5px;
}

.TransferFromTopBlock-component .transfer-module-container {
  margin-top: 35px;
}
.TransferFromTopBlock-component .incoming-txn-label-container {
  margin-bottom: 25px;
}
.TransferFromTopBlock-component .incoming-txn-label-container :not(:first-child) {
  margin-top: 5px;
}

.UserVerifyBlock-component .description {
  font-size: 18px;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  margin-bottom: 25px;
}
.UserVerifyBlock-component .description-header {
  font-size: 24px;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  font-weight: 600;
  margin-bottom: 15px;
}
.UserVerifyBlock-component .error-message {
  margin-top: 10px;
  text-align: center;
  line-height: 13px;
}
.UserVerifyBlock-component .error-message a {
  font-size: 11px;
  color: #FF5635;
  font-weight: bold;
  margin-left: 3px;
  opacity: 0.6;
}
.UserVerifyBlock-component .error-message span {
  font-size: 11px;
  color: #FF5635;
  font-weight: bold;
  margin-left: 3px;
  opacity: 0.6;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.UserVerifyBlock-component .cta-button-container {
  margin-top: 25px;
}
.UserVerifyBlock-component .toggle-verification-type {
  width: 100% !important;
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  margin-top: 10px;
  display: block;
  width: 145px;
  cursor: pointer;
}
.UserVerifyBlock-component .email-address-label {
  font-weight: 500;
  margin-bottom: 5px;
}

.PasswordAuthenticateBlock-component .description {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 100%;
  margin-bottom: 12px;
}
.PasswordAuthenticateBlock-component .subdescription {
  text-align: center;
  font-size: 18px;
  line-height: 150%;
}
.PasswordAuthenticateBlock-component .password-input-container {
  margin-top: 25px;
}
.PasswordAuthenticateBlock-component .forgot-password-container {
  margin-top: 10px;
  margin-bottom: 30px;
}
.PasswordAuthenticateBlock-component .forgot-password-container a {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  display: block;
}
.PasswordAuthenticateBlock-component .locked-message-container .forgot-password-container {
  margin-top: 20px;
  margin-bottom: 0px;
}
.PasswordAuthenticateBlock-component .locked-message-container .forgot-password-container a {
  text-align: center;
}
.PasswordAuthenticateBlock-component .CTAButton-component {
  margin-top: 20px !important;
}
.PasswordAuthenticateBlock-component .error-message {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  line-height: 130%;
}
.PasswordAuthenticateBlock-component .locked-message {
  font-size: 18px;
  text-align: center;
  line-height: 130%;
}

.locked-message {
  font-size: 18px;
  text-align: center;
  line-height: 130%;
  margin-bottom: 15px;
}

.CreatePasswordBlock-component .description {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.CreatePasswordBlock-component .subdescription {
  font-size: 18px;
  text-align: center;
  margin: 25px 0;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.CreatePasswordBlock-component .subdescription2 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.CreatePasswordBlock-component .no-margin {
  margin: 0 !important;
}
.CreatePasswordBlock-component .pin-description {
  margin-top: 25px;
  font-size: 18px;
  text-align: center;
  line-height: 130%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.CreatePasswordBlock-component .password-input-container {
  margin-bottom: 10px;
}
.CreatePasswordBlock-component .password-validation-feedback-container {
  margin-bottom: 25px;
}
.CreatePasswordBlock-component .error-message {
  margin-top: 10px;
  text-align: center;
}
.CreatePasswordBlock-component .why-a-pin {
  margin: 25px 0;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.CreatePasswordBlock-component .locked-message {
  font-size: 18px;
  text-align: center;
  line-height: 130%;
}

.PinExplainerScreenBlock-component .content {
  font-size: 16px;
  line-height: 150%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.PinExplainerScreenBlock-component .content b {
  font-weight: bold;
}
.PinExplainerScreenBlock-component .cta-container {
  margin-top: 40px;
}
.PinExplainerScreenBlock-component img.why-a-pin {
  display: block;
  margin: 25px 0;
  height: 111px;
}

.PinToPasswordPrimerBlock-component {
  text-align: center;
}
.PinToPasswordPrimerBlock-component .content-header {
  margin-top: 15px;
  line-height: 150%;
  font-size: 24px;
  font-weight: 600;
}
.PinToPasswordPrimerBlock-component .content-sub-header {
  margin-top: 5px;
  line-height: 150%;
  font-size: 18px;
  font-weight: 600;
}
.PinToPasswordPrimerBlock-component .content {
  font-size: 18px;
  line-height: 130%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}
.PinToPasswordPrimerBlock-component .content b {
  font-weight: bold;
  margin: 0;
}
.PinToPasswordPrimerBlock-component .content a {
  font-weight: 600;
}
.PinToPasswordPrimerBlock-component .cta-container {
  margin-top: 25px;
}
.PinToPasswordPrimerBlock-component img.why-a-pin {
  display: block;
  margin: 25px 0;
  height: 111px;
}
.PinToPasswordPrimerBlock-component .consequence-checkbox-container {
  margin-top: 20px;
  display: flex;
}
.PinToPasswordPrimerBlock-component .consequence-checkbox-container .consequence-description {
  text-align: left;
}
.PinToPasswordPrimerBlock-component .consequence-checkbox-container .checkbox-container {
  margin-right: 10px;
}
.PinToPasswordPrimerBlock-component img.pin-to-password {
  height: 71px;
}

.OTPAuthenticateBlock-component .description {
  font-size: 18px;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.OTPAuthenticateBlock-component .phone-number {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.OTPAuthenticateBlock-component .email {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.OTPAuthenticateBlock-component .instruction {
  margin-top: 35px;
  font-size: 16px;
  text-align: center;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.OTPAuthenticateBlock-component .instruction div:last-child {
  margin-top: 5px;
}
.OTPAuthenticateBlock-component .instruction .disabled {
  opacity: 0.5;
}
.OTPAuthenticateBlock-component .instruction .disabled,
.OTPAuthenticateBlock-component .instruction .disabled * {
  pointer-events: none;
}
.OTPAuthenticateBlock-component .instruction a {
  font-weight: bold;
}
.OTPAuthenticateBlock-component .verify-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'icon   input' 'empty  error';
  align-items: center;
  margin-top: 25px;
}
.OTPAuthenticateBlock-component .verify-container .sms-icon {
  grid-area: icon;
  margin-top: 5px;
  margin-right: 12px;
}
.OTPAuthenticateBlock-component .verify-container .mail-icon {
  grid-area: icon;
  margin-right: 12px;
}
.OTPAuthenticateBlock-component .verify-container .input-container {
  flex-grow: 1;
  grid-area: input;
}
.OTPAuthenticateBlock-component .verify-container .error-message {
  flex-grow: 1;
  grid-area: error;
  margin-top: 10px;
  font-size: 14px;
  line-height: 130%;
}
.OTPAuthenticateBlock-component .locked-message-container .locked-message {
  font-size: 18px;
  text-align: center;
  line-height: 130%;
  margin-bottom: 15px;
}
.OTPAuthenticateBlock-component .locked-message-container .forgot-password-container a {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.TwoFaAuthenticateBlock-component .description {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 130%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.TwoFaAuthenticateBlock-component .auth-code-input-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'icon   input' 'empty  error';
  align-items: center;
  margin-top: 25px;
}
.TwoFaAuthenticateBlock-component .auth-code-input-container .twofa-icon {
  grid-area: icon;
  margin-right: 12px;
  margin-bottom: 4px;
}
.TwoFaAuthenticateBlock-component .auth-code-input-container .input-container {
  flex-grow: 1;
  grid-area: input;
}
.TwoFaAuthenticateBlock-component .auth-code-input-container .error-message {
  flex-grow: 1;
  grid-area: error;
  margin-top: 10px;
  font-size: 14px;
  line-height: 130%;
}
.TwoFaAuthenticateBlock-component .locked-message-container .locked-message {
  font-size: 18px;
  text-align: center;
  line-height: 130%;
  margin-bottom: 15px;
}
.TwoFaAuthenticateBlock-component .locked-message-container .forgot-password-container a {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.SignTypedDataBlock-component .signature-header {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.SignTypedDataBlock-component .signature-description {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}
.SignTypedDataBlock-component .expanded-section {
  box-sizing: border-box;
  --tertiaryColorAlpha: 0.1;
  width: 100%;
  border: var(--border);
  border-radius: 7px;
  margin-bottom: 25px;
}
.is-light-theme .SignTypedDataBlock-component .expanded-section,
.is-light-theme.SignTypedDataBlock-component .expanded-section {
  --border: 2px solid #BDBDBD;
}
.is-dark-theme .SignTypedDataBlock-component .expanded-section,
.is-dark-theme.SignTypedDataBlock-component .expanded-section {
  --border: 2px solid #545454;
}
.SignTypedDataBlock-component .expanded-section .expanded-section-content {
  --tertiaryColorAlpha: 1;
  max-height: 165px;
  overflow-y: auto;
  padding: 25px 20px;
}
.SignTypedDataBlock-component .expanded-section .expanded-section-content .param-entry {
  margin-bottom: 20px;
}
.SignTypedDataBlock-component .expanded-section .expanded-section-content .param-entry .param-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
}
.SignTypedDataBlock-component .expanded-section .expanded-section-content .param-entry .param-value {
  --tertiaryColorAlpha: 0.6;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.SignTypedDataBlock-component .expanded-section .expanded-section-content :last-child {
  margin-bottom: 0;
}
.SignTypedDataBlock-component .CTAButton-component div {
  display: flex;
}
.SignTypedDataBlock-component .CTAButton-component svg {
  margin-right: 10px;
}
.SignTypedDataBlock-component .toggle-show-button {
  color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 100%;
  display: block;
}
.SignTypedDataBlock-component .show-more {
  margin-bottom: 35px;
}
.SignTypedDataBlock-component .show-less {
  margin-bottom: 10px;
}
.SignTypedDataBlock-component .add-recovery-email-action {
  border-radius: 7px;
  display: flex;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
}
.is-light-theme .SignTypedDataBlock-component .add-recovery-email-action,
.is-light-theme.SignTypedDataBlock-component .add-recovery-email-action {
  background-color: #EEEEEE;
}
.is-dark-theme .SignTypedDataBlock-component .add-recovery-email-action,
.is-dark-theme.SignTypedDataBlock-component .add-recovery-email-action {
  background-color: #212121;
}
.SignTypedDataBlock-component .add-recovery-email-action h1 {
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}
.SignTypedDataBlock-component .add-recovery-email-action h2 {
  font-size: 14px;
  margin: 0;
}
.SignTypedDataBlock-component .add-recovery-email-action svg {
  margin-right: 15px;
}

.PersonalSignBlock-component .signature-header {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.PersonalSignBlock-component .signature-description {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 35px;
}
.PersonalSignBlock-component .CTAButton-component div {
  display: flex;
}
.PersonalSignBlock-component .CTAButton-component svg {
  margin-right: 10px;
}

.AddRecoveryEmailBlock-component .recovery-email-explanation {
  text-align: center;
  margin: 20px 0px;
}
.AddRecoveryEmailBlock-component .verified {
  margin-top: 5px;
  display: flex;
}
.AddRecoveryEmailBlock-component .verified svg {
  margin-right: 5px;
}
.AddRecoveryEmailBlock-component .verified span {
  color: var(--money);
  font-size: 14px;
  font-weight: 600;
}
.AddRecoveryEmailBlock-component .CTAButton-component {
  margin-top: 20px !important;
}

.ConfirmRecoveryEmailBlock-component {
  text-align: center;
}
.ConfirmRecoveryEmailBlock-component .check-email {
  margin-top: 25px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}
.ConfirmRecoveryEmailBlock-component .email {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-top: 10px;
  overflow-wrap: break-word;
}
.ConfirmRecoveryEmailBlock-component .should-arrive-soon {
  font-size: 16px;
  line-height: 19px;
  margin-top: 30px;
  font-weight: 400;
}
.ConfirmRecoveryEmailBlock-component .retry-actions {
  font-size: 16px;
  line-height: 17px;
  margin-top: 10px;
  line-height: 150%;
}
.ConfirmRecoveryEmailBlock-component .retry-actions .disabled {
  pointer-events: none;
  opacity: 0.5;
}

.SetupRecoveryEmailSuccessBlock-component {
  text-align: center;
}
.SetupRecoveryEmailSuccessBlock-component .email-verified {
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 25px;
}
.SetupRecoveryEmailSuccessBlock-component .celebration-gif {
  width: 140px;
  height: 108px;
}

.ForgotPasswordPleaseContactBlock-component {
  text-align: center;
}
.ForgotPasswordPleaseContactBlock-component .please-contact {
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}
.ForgotPasswordPleaseContactBlock-component .email {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.ForgotPasswordEnterRecoveryEmailBlock-component {
  text-align: center;
}
.ForgotPasswordEnterRecoveryEmailBlock-component .enter-email {
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 25px;
}
.ForgotPasswordEnterRecoveryEmailBlock-component .email {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}
.ForgotPasswordEnterRecoveryEmailBlock-component .TextInput-component {
  margin-bottom: 25px;
}

.ForgotPasswordCheckEmailBlock-component {
  text-align: center;
}
.ForgotPasswordCheckEmailBlock-component .check-email {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  margin: 25px 5px 35px 5px;
}

.AccountOverviewBlock-component .cta-button-container {
  margin-top: 35px;
}
.AccountOverviewBlock-component .send-btn-container {
  margin-top: 15px;
}
.AccountOverviewBlock-component .log-out-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.AccountOverviewBlock-component .log-out-container > a {
  font-weight: bold;
}
.AccountOverviewBlock-component .account-label-container {
  display: flex;
  justify-content: space-between;
}
.AccountOverviewBlock-component .account-label-container .account-label {
  font-weight: bold;
  text-transform: uppercase;
}
.AccountOverviewBlock-component .amount-overview-container {
  margin-top: 22px;
}

.SettingsDirectoryBlock-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.SettingsDirectoryBlock-component > * {
  width: 100%;
}
.SettingsDirectoryBlock-component .inline-button-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SettingsDirectoryBlock-component .inline-button-content .caret-with-label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SettingsDirectoryBlock-component .inline-button-content .caret-with-label .saved-label {
  color: var(--money);
  margin-right: 10px;
}
.SettingsDirectoryBlock-component .settings-category-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
}
.SettingsDirectoryBlock-component .settings-category-container:not(:first-child) {
  margin-top: 25px;
}
.SettingsDirectoryBlock-component .settings-category-container .settings-category-label {
  --tertiaryColorAlpha: 0.6;
  font-size: 14px;
  font-weight: bold;
  margin-left: 9px;
  text-transform: uppercase;
}

.EditPasswordScreenBlock-component .description {
  margin-bottom: 25px;
  text-align: center;
}
.EditPasswordScreenBlock-component .cta-button-container {
  margin-top: 25px;
}
.EditPasswordScreenBlock-component .error-message {
  margin-top: 5px;
}
.EditPasswordScreenBlock-component .password-validation-feedback-container {
  margin-top: 10px;
}

.ExportPrivateKeyTypeBlock-component .reason-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.ExportPrivateKeyTypeBlock-component .reason-checkbox-container .radio-button-container {
  margin-right: 10px;
}
.ExportPrivateKeyTypeBlock-component .reason-checkbox-container .reason-description {
  flex-grow: 1;
}

.ExportPrivateKeyConsequencesBlock-component > :not(:first-child) {
  margin-top: 20px;
}
.ExportPrivateKeyConsequencesBlock-component .cta-button-container {
  margin-top: 25px;
}
.ExportPrivateKeyConsequencesBlock-component .description {
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.ExportPrivateKeyConsequencesBlock-component .description .address {
  font-weight: bold;
}
.ExportPrivateKeyConsequencesBlock-component .consequence-checkbox-container {
  display: flex;
}
.ExportPrivateKeyConsequencesBlock-component .consequence-checkbox-container .checkbox-container {
  margin-right: 10px;
  margin-top: 5px;
}
.ExportPrivateKeyConsequencesBlock-component .consequence-checkbox-container .consequence-description {
  flex-grow: 1;
}

.ExportPrivateKeyReasonBlock-component > :not(:first-child) {
  margin-top: 20px;
}
.ExportPrivateKeyReasonBlock-component .description {
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.ExportPrivateKeyReasonBlock-component .cta-button-container {
  margin-top: 25px;
}
.ExportPrivateKeyReasonBlock-component .reason-checkbox-container {
  display: flex;
}
.ExportPrivateKeyReasonBlock-component .reason-checkbox-container .radio-button-container {
  margin-right: 10px;
}
.ExportPrivateKeyReasonBlock-component .reason-checkbox-container .reason-description {
  flex-grow: 1;
}
.ExportPrivateKeyReasonBlock-component .other-label {
  margin-bottom: 8px;
}

.ExportPrivateKeyEncryptBlock-component .description {
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.ExportPrivateKeyEncryptBlock-component .password-input-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.ExportPrivateKeyEncryptBlock-component .password-input-container .label {
  font-weight: bold;
  text-transform: uppercase;
}
.ExportPrivateKeyEncryptBlock-component .password-input-container > :not(:first-child) {
  margin-top: 8px;
}
.ExportPrivateKeyEncryptBlock-component .password-validation-feedback-container {
  margin-top: 10px;
}
.ExportPrivateKeyEncryptBlock-component .cta-button-container {
  margin-top: 35px;
}
.ExportPrivateKeyEncryptBlock-component .remember-this-password-container {
  margin-top: 20px;
  padding: 15px;
  box-sizing: border-box;
  border-color: #BF9046;
  border-style: solid;
  border-width: 2px 2px 2px 8px;
}
.ExportPrivateKeyEncryptBlock-component .remember-this-password-container .remember-this-password-label {
  text-transform: uppercase;
  font-size: 14px;
  color: #BF9046;
  font-weight: bold;
}
.ExportPrivateKeyEncryptBlock-component .remember-this-password-container .remember-this-password-description {
  margin-top: 5px;
  font-size: 12px;
  color: #BF9046;
}

.ExportPrivateKeySubmittingBlock-component .exporting-animation {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation > *:nth-child(2) {
  margin-left: 18px;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation > *:nth-child(n+3):nth-child(-n+6) {
  margin-left: 15px;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation > *:last-child {
  margin-left: 18px;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation .animated-chevron-1 {
  -webkit-animation: pulse-1 2000ms ease-out infinite;
          animation: pulse-1 2000ms ease-out infinite;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation .animated-chevron-2 {
  -webkit-animation: pulse-2 2000ms ease-out infinite;
          animation: pulse-2 2000ms ease-out infinite;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation .animated-chevron-3 {
  -webkit-animation: pulse-3 2000ms ease-out infinite;
          animation: pulse-3 2000ms ease-out infinite;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation .animated-chevron-4 {
  -webkit-animation: pulse-4 2000ms ease-out infinite;
          animation: pulse-4 2000ms ease-out infinite;
}
.ExportPrivateKeySubmittingBlock-component .exporting-animation .animated-chevron-5 {
  -webkit-animation: pulse-5 2000ms ease-out infinite;
          animation: pulse-5 2000ms ease-out infinite;
}
@-webkit-keyframes pulse-1 {
  0%,
  14.285714285714286% {
    opacity: 0;
  }
  28.571428571428573%,
  100% {
    opacity: 1;
  }
}
@keyframes pulse-1 {
  0%,
  14.285714285714286% {
    opacity: 0;
  }
  28.571428571428573%,
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes pulse-2 {
  0%,
  28.571428571428573% {
    opacity: 0;
  }
  42.85714285714286%,
  100% {
    opacity: 1;
  }
}
@keyframes pulse-2 {
  0%,
  28.571428571428573% {
    opacity: 0;
  }
  42.85714285714286%,
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes pulse-3 {
  0%,
  42.85714285714286% {
    opacity: 0;
  }
  57.142857142857146%,
  100% {
    opacity: 1;
  }
}
@keyframes pulse-3 {
  0%,
  42.85714285714286% {
    opacity: 0;
  }
  57.142857142857146%,
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes pulse-4 {
  0%,
  57.142857142857146% {
    opacity: 0;
  }
  71.42857142857143%,
  100% {
    opacity: 1;
  }
}
@keyframes pulse-4 {
  0%,
  57.142857142857146% {
    opacity: 0;
  }
  71.42857142857143%,
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes pulse-5 {
  0%,
  71.42857142857143% {
    opacity: 0;
  }
  85.71428571428572%,
  100% {
    opacity: 1;
  }
}
@keyframes pulse-5 {
  0%,
  71.42857142857143% {
    opacity: 0;
  }
  85.71428571428572%,
  100% {
    opacity: 1;
  }
}
.ExportPrivateKeySubmittingBlock-component .description {
  margin-top: 22px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  --tertiaryColorAlpha: 0.5;
}
.ExportPrivateKeySubmittingBlock-component .error-description {
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 35px;
  text-align: center;
}
.ExportPrivateKeySubmittingBlock-component .error-description .support-link {
  font-size: 19px;
  font-weight: bold;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.ExportPrivateKeySuccessBlock-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ExportPrivateKeySuccessBlock-component .encrypted-file-icon {
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  height: 80px;
}
.ExportPrivateKeySuccessBlock-component .success-headline {
  margin: 20px 0 25px 0;
  font-size: 24px;
  line-height: 130%;
  font-weight: bold;
  text-align: center;
}
.ExportPrivateKeySuccessBlock-component .did-not-receive-link {
  font-weight: bold;
  text-align: center;
}
.ExportPrivateKeySuccessBlock-component .cta-button-container {
  width: 100%;
}
.ExportPrivateKeySuccessBlock-component .retry-cta-button-container {
  width: 300px;
  text-align: center;
}
.ExportPrivateKeySuccessBlock-component .cta-button-container {
  margin-bottom: 20px;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.ExportPrivateKeySuccessBlock-component .cta-button-container .with-retry-download-button {
  margin-bottom: 15px;
}

.ExportPrivateKeyEndStepBlock-component .export-end-step-headline {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 150%;
  text-align: center;
}
.ExportPrivateKeyEndStepBlock-component .export-end-step-subheadline {
  margin: 10px 0 30px 0;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}

.ExportPrivateKeyMobileSafariExplainerBlock-component {
  display: flex;
  flex-direction: column;
}
.ExportPrivateKeyMobileSafariExplainerBlock-component .content {
  font-size: 16px;
  line-height: 150%;
}
.ExportPrivateKeyMobileSafariExplainerBlock-component .cta-container {
  margin-top: 40px;
}
.ExportPrivateKeyMobileSafariExplainerBlock-component img.desktop-recommended {
  display: block;
  margin: 25px 0;
  height: 85px;
}

.ExportPrivateKeyUnderConstructionBlock-component {
  display: flex;
  flex-direction: column;
}
.ExportPrivateKeyUnderConstructionBlock-component .headline {
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  font-weight: bold;
}
.ExportPrivateKeyUnderConstructionBlock-component .content {
  margin-top: 5px;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.ExportPrivateKeyUnderConstructionBlock-component .content * {
  font-size: 18px;
}
.ExportPrivateKeyUnderConstructionBlock-component .cta-container {
  margin-top: 25px;
}
.ExportPrivateKeyUnderConstructionBlock-component img.under-maintenance {
  display: block;
  margin-top: 10px;
  margin-bottom: 25px;
  height: 85px;
}

.TwoFaBeginningStepBlock-component .install-app-label,
.TwoFaBeginningStepBlock-component .two-fa-status-label {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.TwoFaBeginningStepBlock-component .install-app-description,
.TwoFaBeginningStepBlock-component .two-fa-status-description {
  margin: 10px 0 30px 0;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.TwoFaBeginningStepBlock-component .two-fa-status-description {
  margin-top: 15px;
}

.TwoFaBackupBlock-component {
  display: flex;
  flex-direction: column;
}
.TwoFaBackupBlock-component .emergency-backup-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TwoFaBackupBlock-component .emergency-backup-img-container img {
  width: 92px;
}
.TwoFaBackupBlock-component .emergency-backup-headline {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.TwoFaBackupBlock-component .emergency-backup-subheadline {
  margin-top: 15px;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.TwoFaBackupBlock-component .cta-button-container {
  width: 100%;
}
.TwoFaBackupBlock-component .cta-button-container.primary {
  margin-top: 15px;
  flex-basis: 100%;
}
.TwoFaBackupBlock-component .cta-button-container.secondary {
  margin-top: 25px;
}
.TwoFaBackupBlock-component .cta-button-container.secondary.has-print-button {
  display: flex;
}
.TwoFaBackupBlock-component .cta-button-container.secondary.has-print-button > * {
  flex-basis: 50%;
}
.TwoFaBackupBlock-component .cta-button-container.secondary.has-print-button > *:first-child {
  margin-right: 10px !important;
}
.TwoFaBackupBlock-component .cta-button-container .print-label {
  margin-left: 10px;
}

/*
* Used for printing the backup secret + QR Code during 2FA setup.
* Html2Canvas scales down the font sizes during image generation
* So styles need to be exaggerated
* The following media query scales it down to actual size.
*/
@media print {
  body * {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }
  .backup-secret-print-wrapper {
    opacity: 1 !important;
    top: -300px !important;
  }
  .backup-secret-print {
    top: 0px !important;
    left: -30px !important;
    width: 750px !important;
    height: 1800px !important;
  }
  .backup-secret-print .inside {
    top: 0 !important;
    margin: 60px !important;
  }
  .backup-secret-print .inside div {
    font-size: 14px !important;
    line-height: 26px !important;
  }
  .backup-secret-print .inside div b {
    font-size: 14px !important;
    line-height: 26px !important;
    font-weight: 600 !important;
  }
  .backup-secret-print .inside div span {
    font-size: 14px !important;
    line-height: 26px !important;
  }
  .backup-secret-print .inside .emergency-backup-banner {
    height: 50px !important;
    margin-bottom: 20px !important;
  }
  .backup-secret-print .inside .divider {
    background: rgba(0, 0, 0, 0.2) !important;
    height: 1px !important;
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .backup-secret-print .inside .instruction {
    margin-bottom: 20px !important;
  }
  .backup-secret-print .inside .instruction .desc {
    line-height: 26px !important;
    margin-left: 15px !important;
  }
  .backup-secret-print .inside .instruction .desc b {
    line-height: 26px !important;
  }
  .backup-secret-print .inside .scan-code-instructions {
    margin-top: 20px !important;
    margin-left: 20px !important;
  }
  .backup-secret-print .inside .scan-code-instructions .secret-details {
    padding: 20px 20px !important;
  }
  .backup-secret-print .inside .scan-code-instructions .code-section {
    padding: 20px 20px !important;
  }
  .backup-secret-print .inside .scan-code-instructions .code-section .display-code-section .qrcode {
    width: 165px !important;
  }
  .backup-secret-print .inside .scan-code-instructions .code-section .display-code-section .secret {
    font-size: 14px !important;
  }
  .backup-secret-print * {
    visibility: visible;
  }
}
@media screen {
  .backup-secret-print {
    top: -100px;
    left: -100px;
  }
}
.backup-secret .emergency-backup-description {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 25px 0;
}
.backup-secret .emergency-backup-description div {
  margin-top: 35px;
  color: white;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  width: 140px;
}
.backup-secret .emergency-backup-description img {
  height: 100px;
  margin-left: -3px;
}
.backup-secret .backup-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.backup-secret .backup-actions .action-btn {
  width: 100px;
  cursor: pointer;
  color: #8095FF;
  border: 2px solid #8095FF;
  border-radius: 7px;
  padding: 10px;
  text-align: center;
  line-height: 19px;
  display: flex;
  justify-content: center;
}
.backup-secret .backup-actions .action-btn div {
  font-weight: 600;
  font-size: 16px;
}
.backup-secret .backup-actions .action-btn img {
  margin-right: 8px;
  color: #8095FF;
}
.backup-secret .backup-actions .action-btn .share-icon {
  margin-top: -2px;
  margin-right: 10px;
}
.backup-secret .backup-actions .mobile {
  width: 100%;
}
.adjust-letter-space .inside {
  letter-spacing: 8px;
}
.backup-secret-print-wrapper {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.backup-secret-print {
  position: absolute;
  width: 1450px;
  height: 1800px;
  left: 0;
  background: white;
}
.backup-secret-print .inside {
  margin: 200px 100px;
}
.backup-secret-print .inside div {
  font-size: 28px;
  line-height: 60px;
}
.backup-secret-print .inside div b {
  font-size: 28px;
  line-height: 60px;
  font-weight: 600;
}
.backup-secret-print .inside div span {
  font-size: 28px;
  line-height: 60px;
}
.backup-secret-print .inside .emergency-backup-banner {
  height: 120px;
  margin-bottom: 40px;
}
.backup-secret-print .inside .divider {
  background: rgba(0, 0, 0, 0.2);
  height: 1px;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}
.backup-secret-print .inside .instruction {
  margin-bottom: 40px;
}
.backup-secret-print .inside .instruction .heading {
  font-weight: 600;
}
.backup-secret-print .inside .instruction .desc {
  line-height: 40px;
  margin-left: 30px;
  font-size: 24px;
}
.backup-secret-print .inside .instruction .desc b {
  line-height: 40px;
  font-size: 24px;
}
.backup-secret-print .inside .scan-code-instructions {
  border: 2px solid #617BFF;
  border-radius: 14px;
  margin-top: 40px;
  margin-left: 25px;
}
.backup-secret-print .inside .scan-code-instructions .secret-details {
  background: rgba(97, 123, 255, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
}
.backup-secret-print .inside .scan-code-instructions .secret-details div {
  font-size: 24px;
}
.backup-secret-print .inside .scan-code-instructions .secret-details b {
  font-size: 24px;
}
.backup-secret-print .inside .scan-code-instructions .code-section {
  display: flex;
  padding: 50px;
  border-top: 2px solid #617BFF !important;
}
.backup-secret-print .inside .scan-code-instructions .code-section .qrcode {
  margin-right: 60px;
}
.backup-secret-print .inside .scan-code-instructions .code-section .display-code-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.backup-secret-print .inside .scan-code-instructions .code-section .display-code-section .code-header {
  font-weight: 600 !important;
}
.backup-secret-print .inside .scan-code-instructions .code-section .display-code-section .secret {
  font-family: 'SourceCodePro';
  font-size: 28px;
}
@media only screen and (max-width: 500px) {
  .backup-secret-print-wrapper {
    top: 0;
    left: 0;
    top: 1000px;
  }
  .backup-secret-print {
    width: 950px;
    height: 1200px;
    margin: 0px !important;
    top: 0px !important;
  }
  .backup-secret-print .inside {
    margin: 0px !important;
    letter-spacing: 5px !important;
  }
  .backup-secret-print .inside div {
    font-size: 20px !important;
  }
  .backup-secret-print .inside div b {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .backup-secret-print .inside div span {
    font-size: 20px !important;
  }
  .backup-secret-print .inside .emergency-backup-banner {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
  .backup-secret-print .inside .divider {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .backup-secret-print .inside .instruction {
    margin-bottom: 40px !important;
  }
  .backup-secret-print .inside .instruction .desc {
    margin-left: 35px !important;
  }
  .backup-secret-print .inside .scan-code-instructions {
    margin-top: 10px !important;
    margin-left: 20px !important;
  }
  .backup-secret-print .inside .scan-code-instructions .secret-details {
    padding: 25px !important;
  }
  .backup-secret-print .inside .scan-code-instructions .code-section {
    padding: 20px !important;
  }
}

.TwoFaAuthAppSetupBlock-component .description {
  font-size: 16px;
  text-align: center;
  line-height: 150%;
}
.TwoFaAuthAppSetupBlock-component .QRCode-component {
  justify-content: center;
  margin-top: 30px;
}
.TwoFaAuthAppSetupBlock-component .secret-container,
.TwoFaAuthAppSetupBlock-component .reveal-secret-cta {
  display: block;
  margin-top: 25px;
  text-align: center;
}
.TwoFaAuthAppSetupBlock-component .input-this-code-label {
  text-transform: uppercase;
}
.TwoFaAuthAppSetupBlock-component .secret-label {
  font-family: 'SourceCodePro';
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 7px;
  font-size: 18px;
  word-break: break-all;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.is-light-theme .TwoFaAuthAppSetupBlock-component .secret-label,
.is-light-theme.TwoFaAuthAppSetupBlock-component .secret-label {
  background-color: #EEEEEE;
}
.is-dark-theme .TwoFaAuthAppSetupBlock-component .secret-label,
.is-dark-theme.TwoFaAuthAppSetupBlock-component .secret-label {
  background-color: #212121;
}
.TwoFaAuthAppSetupBlock-component .cta-button-container {
  margin-top: 35px;
}

.TwoFaConfirmOrDisableAppBlock-component .description {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 130%;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.TwoFaConfirmOrDisableAppBlock-component .auth-code-input-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'icon   input' 'empty  error';
  align-items: center;
  margin-top: 25px;
}
.TwoFaConfirmOrDisableAppBlock-component .auth-code-input-container .twofa-icon {
  grid-area: icon;
  margin-right: 12px;
  margin-bottom: 4px;
}
.TwoFaConfirmOrDisableAppBlock-component .auth-code-input-container .input-container {
  flex-grow: 1;
  grid-area: input;
}
.TwoFaConfirmOrDisableAppBlock-component .auth-code-input-container .error-message {
  flex-grow: 1;
  grid-area: error;
  margin-top: 10px;
  font-size: 14px;
  line-height: 130%;
}
.TwoFaConfirmOrDisableAppBlock-component .cta-button-container {
  margin-top: 35px;
}

.TwoFaEndStepBlock-component {
  text-align: center;
}
.TwoFaEndStepBlock-component .two-fa-setup-success {
  font-size: 23px;
  line-height: 27px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 25px;
}
.TwoFaEndStepBlock-component .celebration-gif {
  width: 140px;
  height: 108px;
}

.ApplicationError-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ApplicationError-component .error-wrapper {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 4px 4px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 9px;
}
.ApplicationError-component .error-wrapper .close-button {
  cursor: pointer;
  width: 25px;
  float: right;
}
.ApplicationError-component .error-wrapper .close-button svg {
  margin-top: -25px;
}
.ApplicationError-component .error-wrapper .close-button:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}
.ApplicationError-component .error-wrapper .close-button * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.ApplicationError-component .error-wrapper .application-error-container {
  margin: 0 auto;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  --primaryColorAlpha: 1;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  padding: 24px;
}
.ApplicationError-component .error-wrapper .application-error-container .application-error-title {
  font-size: 24px;
  font-weight: bold;
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
}
.ApplicationError-component .error-wrapper .application-error-container .application-error-message {
  font-size: 14px;
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  margin-top: 15px;
}
.ApplicationError-component .error-wrapper .error-details {
  --secondaryColorAlpha: 1;
  background-color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 25px;
}
.ApplicationError-component .error-wrapper .error-details .details-label {
  font-size: 16px;
  font-weight: bold;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.ApplicationError-component .error-wrapper .error-details .error-code {
  font-size: 14px;
  margin-top: 10px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.ApplicationError-component .error-wrapper .error-details a,
.ApplicationError-component .error-wrapper .error-details span {
  font-size: 14px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.NavigationBar-component {
  height: 60px;
  width: 100%;
  background-color: transparent;
}
.NavigationBar-component:not(.has-collapsed-height) + .page-container .modal-page {
  padding-top: 39px;
  -webkit-transition: padding-top 400ms;
  transition: padding-top 400ms;
  will-change: padding-top;
}
.NavigationBar-component .modal-actions {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 18px;
  height: 60px;
  box-sizing: border-box;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
}
.NavigationBar-component .modal-actions > .left {
  width: 33%;
}
.NavigationBar-component .modal-actions > .center {
  flex-grow: 1;
  max-width: calc(100% - 5% - 10px);
}
.NavigationBar-component .modal-actions > .center.has-left-action {
  max-width: calc(100% - 33% - 5% - (10px * 2));
}
.NavigationBar-component .modal-actions > .right {
  width: 33%;
  min-width: 5%;
}
.NavigationBar-component .back-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.is-light-theme .NavigationBar-component .back-button:hover,
.is-light-theme .NavigationBar-component .back-button:active,
.is-light-theme.NavigationBar-component .back-button:hover,
.is-light-theme.NavigationBar-component .back-button:active {
  color: rgba(var(--secondaryColorDarker), var(--secondaryColorDarkerAlpha));
}
.is-dark-theme .NavigationBar-component .back-button:hover,
.is-dark-theme .NavigationBar-component .back-button:active,
.is-dark-theme.NavigationBar-component .back-button:hover,
.is-dark-theme.NavigationBar-component .back-button:active {
  color: rgba(var(--secondaryColorLighter), var(--secondaryColorLighterAlpha));
}
.NavigationBar-component .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
}
.NavigationBar-component .account-button,
.NavigationBar-component .close-button {
  display: flex;
  justify-content: flex-end;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}
.NavigationBar-component .account-button *,
.NavigationBar-component .close-button * {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.NavigationBar-component .account-button > span,
.NavigationBar-component .close-button > span {
  cursor: pointer;
}
.NavigationBar-component .account-button {
  margin-right: 10px;
}
.NavigationBar-component .close-button {
  margin-left: 10px;
}
.NavigationBar-component .logo-container {
  position: absolute;
  width: 69px;
  height: 69px;
  top: 24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}
.NavigationBar-component .logo-container img {
  height: 100%;
  width: 100%;
  border: 3px solid #ffffff;
  border-radius: 50%;
  background-color: #ffffff;
  object-fit: contain;
}

.AutoLogoutMessage-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.AutoLogoutMessage-component .logout-message-wrapper {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 4px 4px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 9px;
  --primaryColorAlpha: 1;
  background-color: rgba(var(--primaryColor), var(--primaryColorAlpha));
  padding: 24px;
}
.AutoLogoutMessage-component .logout-message-wrapper .logout-title {
  display: flex;
}
.AutoLogoutMessage-component .logout-message-wrapper .logout-title svg {
  width: 25px;
  margin-right: 10px;
}
.AutoLogoutMessage-component .logout-message-wrapper .logout-title .good-bye {
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
}
.AutoLogoutMessage-component .logout-message-wrapper .logout-message {
  font-size: 14px;
  color: rgba(var(--secondaryColor), var(--secondaryColorAlpha));
  margin-top: 15px;
  font-weight: 500;
}

.Footer-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Footer-component .separator-container {
  width: 100%;
  opacity: 0;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.Footer-component .separator-container.is-showing {
  opacity: 1;
}
.Footer-component .secured-by-fortmatic-label {
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-dark-theme .Footer-component .secured-by-fortmatic-label,
.is-dark-theme.Footer-component .secured-by-fortmatic-label {
  --tertiaryColorAlpha: 0.6;
}
.is-light-theme .Footer-component .secured-by-fortmatic-label,
.is-light-theme.Footer-component .secured-by-fortmatic-label {
  --tertiaryColorAlpha: 0.3;
}
.Footer-component .secured-by-fortmatic-label div {
  font-size: 12px;
  color: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}
.Footer-component .secured-by-fortmatic-label svg {
  margin-left: 8px;
  fill: rgba(var(--tertiaryColor), var(--tertiaryColorAlpha));
}

.modal-footer-banner {
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: white;
  width: 100%;
  border-left: 3px solid #617AFF;
  box-shadow: 2px -3px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  padding: 15px 15px 10px 15px;
}
.modal-footer-banner * {
  color: #211F2B !important;
}
.modal-footer-banner h1 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  margin: 0;
  margin-right: 15px;
  margin-top: -2px;
}
.modal-footer-banner h1 img {
  margin-right: 10px;
  height: 16px;
}
.modal-footer-banner h2 {
  font-size: 12px;
  line-height: 14px;
  margin: 5px 50px 5px 27px;
  font-weight: normal;
}
.modal-footer-banner h2 a {
  font-size: 12px;
  text-decoration: none;
  color: #617AFF !important;
  font-weight: 600;
  margin-left: 5px;
}
.modal-footer-banner svg {
  position: absolute;
  right: 45px;
  top: 15px;
  cursor: pointer;
}

